<template>
  <div class="card packages-per-month-container">
    <div class="card-body">
      <h4 class="card-title">
        Pacotes cadastrados
      </h4>

      <small class="card-text">
        Aqui você pode visualizar o número de pacotes cadastrados por mês na sua conta. Levamos em consideração os pacotes monitorados atualmente e os já excluídos.
      </small>

      <div v-if="request_pending">
        <ClipLoader color="#f3b008" />
      </div>

      <div
        v-else
        class="pt-2"
      >
        <div v-if="!renderChart">
          <hr />

          <p class="card-text">
            <template v-if="error">
              Não há dados para serem exibidos aqui
            </template>
            <template v-else>
              Ocorreu um erro inesperado, recarregue a página
            </template>
          </p>
        </div>

        <SPEBarChart
          v-if="chart_data.labels.length > 0"
          :chart-data="chart_data"
          :style="graphStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import _ from 'lodash';
  import moment from 'moment';

  import SPEBarChart from './charts/SPEBarChart.vue';

  import { fetchPackagesPerMonthMetric } from '../../api';

  export default {
    name: 'PackagesPerMonthChartContainer',
    components: {
      ClipLoader,
      SPEBarChart,
    },

    data() {
      return {
        request_pending: false,
        error: false,

        chart_data: {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: '#f3b008',
            borderColor: '#f3b008',
            borderWidth: 1,
            barThickness: 40,
          }],
        },
      };
    },

    computed: {
      renderChart() {
        return this.chart_data.labels.length > 0 || !this.error;
      },

      graphStyle() {
        return {
          position: 'relative',
          height: '150px'
        };
      },
    },

    async mounted() {
      await this.fetchChartData();
    },

    methods: {
      mountChart(data) {
        const chart_labels = _(data).map('label')
          .map(period => {
            return moment(period, 'MM/YYYY')
              .format('MMM')
              .toUpperCase();
          }).value();

        const chart_data = _.map(data, 'value');

        this.chart_data.labels = chart_labels;
        this.chart_data.datasets[0].data = chart_data;
      },

      async fetchChartData(start_date, end_date) {
        try {
          this.request_pending = true;
          const { data: { data } } = await fetchPackagesPerMonthMetric(start_date, end_date);

          return this.mountChart(data);
        } catch (err) {
          const { response: { status } = {} } = err;

          if (status === 401) {
            this.$emit('show-modal');
          }

          this.error = true;
        } finally {
          this.request_pending = false;
        }
      }
    }
  };
</script>

<style scoped>
  .packages-per-month-container {
    grid-row: 5/7;
    grid-column: 2/3;
  }
</style>
