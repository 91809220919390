<template>
  <div class="d-inline-block text-right copy-button ml-2">
    <button
      v-clipboard:copy="content"
      type="button"
      class="btn btn-outline-primary copy-button"
    >
      <i class="far fa-copy" />
      <span>
        Copiar
      </span>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'CopyButton',
    props: {
      content:{
        type: String,
        required: true,
      }
    },
  };
</script>

<style scoped>
  .copy-button { border: none !important; }
</style>