<template>
  <div class="card my-3">
    <div class="card-body">
      <h3 class="card-title">
        Cadastrar novo pacote
      </h3>

      <p class="card-text">
        Para monitorar um novo pacote, preencha o formulário, para saber mais

        <a
          :href="manual_url"
          target="_blank"
        >
          clique aqui.
        </a>
      </p>

      <hr />

      <form
        :action="uri_create_package"
        method="post"
        @submit="submitForm"
      >
        <input
          type="hidden"
          name="_token"
          :value="csrf_token"
        />

        <div class="row my-2">
          <div class="col-12 col-sm-4 d-flex flex-column align-items-start mb-2 mb-sm-0">
            <label
              for="shipping_company"
              :class="labelWithError('shipping_company')"
            >
              Transportadora
            </label>

            <select
              id="search_shipping_company"
              v-model="shipping_company"
              :class="inputHasErrors('shipping_company')"
              class="form-control mr-sm-2"
              name="shipping_company"
              @blur="$v.shipping_company.$touch()"
              @change="clearFeedbackErrors('shipping_company')"
            >
              <option value="">
                Selecione uma transportadora
              </option>

              <option
                v-for="(company, index) in shipping_companies"
                :key="index"
                :value="company"
              >
                {{ renderCarrierName(company) }}
              </option>
            </select>

            <div>
              <div>
                <span
                  v-if="!$v.shipping_company.required && $v.shipping_company.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  A transportadora é obrigatória
                </span>
              </div>

              <div>
                <span
                  v-if="errors.has('shipping_company')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('shipping_company') }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-4 d-flex flex-column align-items-start">
            <label
              for="tracking_code"
              :class="labelWithError('tracking_code')"
            >
              Código de rastreio
            </label>

            <input
              id="search_tracking_code"
              v-model="tracking_code"
              :class="inputHasErrors('tracking_code')"
              class="form-control mr-sm-2"
              name="tracking_code"
              placeholder="BR1223321312123X"
              type="text"
              @blur="$v.tracking_code.$touch()"
              @input="clearFeedbackErrors('tracking_code')"
            />

            <div>
              <div>
                <span
                  v-if="!$v.tracking_code.required && $v.tracking_code.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  O código de rastreio é obrigatório
                </span>
              </div>

              <div>
                <span
                  v-if="!$v.tracking_code.maxLength && $v.tracking_code.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  O código de rastreio deve possuir no máximo {{ max_length_global }} caracteres
                </span>
              </div>

              <div>
                <span
                  v-if="errors.has('tracking_code')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('tracking_code') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="col-12 col-sm-4 d-flex flex-column align-items-start mb-2 mb-sm-0">
            <label
              for="description"
              :class="labelWithError('description')"
            >
              Descrição
            </label>

            <input
              id="search_description"
              v-model="description"
              :class="inputHasErrors('description')"
              class="form-control mr-sm-2"
              name="description"
              placeholder="Controle de TV"
              type="text"
              @blur="$v.description.$touch()"
              @input="clearFeedbackErrors('description')"
            />

            <div>
              <div>
                <span
                  v-if="!$v.description.required && $v.description.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  A descrição é obrigatória
                </span>
              </div>

              <div>
                <span
                  v-if="!$v.description.maxLength && $v.description.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  A descrição deve possuir no máximo {{ max_length_global }} caracteres
                </span>
              </div>

              <div>
                <span
                  v-if="errors.has('description')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('description') }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-4 d-flex flex-column align-items-start">
            <label
              for="customer_name"
              :class="labelWithError('customer_name')"
            >
              Cliente
            </label>

            <input
              id="search_customer_name"
              v-model="customer_name"
              :class="inputHasErrors('customer_name')"
              class="form-control mr-sm-2"
              name="customer_name"
              placeholder="João Pedro"
              type="text"
              @blur="$v.customer_name.$touch()"
              @input="clearFeedbackErrors('customer_name')"
            />

            <div>
              <div>
                <span
                  v-if="!$v.customer_name.maxLength && $v.customer_name.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  A descrição deve possuir no máximo {{ max_length_global }} caracteres
                </span>
              </div>

              <div>
                <span
                  v-if="errors.has('customer_name')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('customer_name') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-sm-3 d-flex flex-column align-items-start mb-2 mb-sm-0">
            <label
              for="customer_phone"
              :class="labelWithError('customer_phone')"
            >
              Telefone
            </label>

            <input
              v-model="customer_phone"
              type="hidden"
              name="customer_phone"
            />

            <VuePhoneNumberInput
              v-model="unformatted_phone"
              :translations="translations"
              clearable
              color="#f3b008"
              default-country-code="BR"
              error-color="#e94038"
              name="unformattedPhone"
              size="sm"
              valid-color="#f3b008"
              @update="formatPhone"
            />

            <div>
              <div>
                <span
                  v-if="!$v.customer_phone.validPhone && $v.customer_phone.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  O telefone deve ser válido no formato internacional (+55 31 99123-4567)
                </span>
              </div>

              <div>
                <span
                  v-if="errors.has('customer_phone')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('customer_phone') }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-5 d-flex flex-column align-items-start">
            <label
              for="customer_email"
              :class="labelWithError('customer_email')"
            >
              E-mail
            </label>

            <input
              id="search_customer_email"
              v-model="customer_email"
              type="text"
              class="form-control mr-sm-2"
              :class="inputHasErrors('customer_email')"
              name="customer_email"
              placeholder="cliente@gmail.com"
            />

            <div>
              <div>
                <span
                  v-if="!$v.customer_email.email && $v.customer_email.$error"
                  class="text-danger font-weight-bold text-small"
                >
                  O e-mail deve ter um formato válido
                </span>
              </div>

              <div>
                <span
                  v-if="errors.has('email')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('email') }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-2 offset-sm-2 mt-auto">
            <button
              type="submit"
              class="btn btn-primary btn-block text-white mt-3 mt-sm-0"
            >
              Monitorar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { required, maxLength, email, nullable } from 'vuelidate/lib/validators';
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import Errors from '../../../domain/Errors';
  import carriers from '../../../enum/carriers';

  export default {
    name: 'CreatePackageForm',
    components: { VuePhoneNumberInput },
    props: {
      backend_errors: {
        type: [ Array, Object ],
        required: true,
      },

      csrf_token: {
        type: String,
        required: true,
      },

      fill: {
        type: [ Array, Object ],
        required: true,
      },

      manual_url: {
        type: String,
        required: true,
      },

      shipping_companies: {
        type: Array,
        required: true,
      },

      uri_create_package: {
        type: String,
        required: true,
      },
    },

    validations() {
      return {
        customer_phone: {
          validNumber: function() {
            return this.valid_phone_number;
          }
        },

        customer_name: {
          maxLength: maxLength(this.max_length_global),
        },

        description: {
          required,
          maxLength: maxLength(this.max_length_global),
        },

        customer_email: {
          email,
          maxLength: maxLength(this.max_length_global),
        },

        tracking_code: {
          required,
          maxLength: maxLength(this.max_length_global),
        },

        shipping_company: {
          required,
        }
      };
    },

    data() {
      return {
        errors: new Errors(this.backend_errors),
        customer_email: '',
        customer_name: '',
        customer_phone: '',
        description: '',
        shipping_company: '',
        tracking_code: '',
        valid_phone_number: true,
        unformatted_phone: '',
        max_length_global: 255,
        translations: {
          countrySelectorLabel: 'Código do país',
          phoneNumberLabel: '(31) 99999-9999',
          example: 'Exemplo: '
        },
        carriers,
      };
    },

    created() {
      if (!Array.isArray(this.fill)) {
        this.customer_email = this.fill.customer_email;
        this.customer_name = this.fill.customer_name;
        this.description = this.fill.description;
        this.shipping_company = this.fill.shipping_company;
        this.tracking_code = this.fill.tracking_code;
        this.unformatted_phone = this.fill.customer_phone;
      }
    },

    methods: {
      renderCarrierName(company) {
        if (this.carriers[company]) {
          return this.carriers[company];
        }

        return company;
      },

      inputHasErrors(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'is-invalid';
        }
      },

      labelWithError(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'text-danger';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors.clear(field);
      },

      formatPhone(phone) {
        this.customer_phone = phone.formattedNumber;
        this.valid_phone_number = phone.isValid;
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },
    },
  };
</script>
