import Vue from 'vue';
import VueI18n from 'vue-i18n';

const langs = import.meta.glob('../../../lang/*.json', { eager: true });
const messages = {
  en: langs['../../../lang/php_en.json'].default,
  'pt-BR': { ...langs['../../../lang/php_pt-BR.json'].default, ...langs['../../../lang/pt-BR.json'].default },
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'pt-BR',
  messages,
});
