<template>
  <div>
    <form
      method="POST"
      :action="uri_register"
      @submit="submitForm"
    >
      <input
        type="hidden"
        :value="pre_register_token"
        name="token"
      />

      <input
        type="hidden"
        name="_token"
        :value="csrf_token"
      />

      <div class="row pt-1 mx-auto">
        <div class="col-12 col-xl-8 mx-auto">
          <div class="form-group row d-flex justify-content-center">
            <div class="col-12">
              <label
                for="name"
                class="text-left"
              >
                Nome
              </label>

              <input
                id="name"
                v-model="name"
                type="text"
                class="form-control"
                name="name"
                disabled
              />
            </div>

            <div class="col-12">
              <label
                for="email"
                class="text-left"
              >
                Email
              </label>

              <input
                id="email"
                v-model="email"
                type="text"
                class="form-control"
                name="email"
                disabled
              />
            </div>

            <div class="col-12">
              <label
                for="password"
                class="text-left"
              >
                Senha
              </label>

              <input
                id="password"
                v-model="password"
                type="password"
                class="form-control"
                :class="inputHasErrors('password')"
                name="password"
                @blur="$v.password.$touch()"
                @input="clearFeedbackErrors('password')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.password.required && $v.password.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha é obrigatória
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.password.minLength && $v.password.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha deve possuir no mínimo {{ min_password_length }} caracteres
                  </span>
                </div>

                <span
                  v-if="errors.has('password')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('password') }}
                </span>
              </div>
            </div>

            <div class="col-12">
              <label
                for="password_confirmation"
                class="text-left"
              >
                Confirmação de senha
              </label>

              <input
                id="password_confirmation"
                v-model="password_confirmation"
                type="password"
                class="form-control"
                :class="inputHasErrors('password_confirmation')"
                name="password_confirmation"
                @blur="$v.password_confirmation.$touch()"
                @input="clearFeedbackErrors('password_confirmation')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.password_confirmation.required && $v.password_confirmation.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha deve ser confirmada
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.password_confirmation.sameAsPassword && $v.password_confirmation.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    As duas senhas devem ser iguais
                  </span>
                </div>

                <span
                  v-if="errors.has('password_confirmation')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('password_confirmation') }}
                </span>
              </div>

              <button
                id="submit_button"
                class="col-12 mx-auto d-block mx-auto btn btn-primary my-3 text-white"
                type="submit"
              >
                Salvar senha
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { required, minLength, sameAs } from 'vuelidate/lib/validators';

  import Errors from '../../domain/Errors';

  export default {
    name: 'PreRegisterForm',
    props: {
      backend_errors: {
        required: true,
        type: [ Array, Object ],
      },

      user: {
        required: true,
        type: Object,
      },

      csrf_token: {
        required: true,
        type: String,
      },

      uri_register: {
        required: true,
        type: String,
      },
    },

    data() {
      return {
        errors: new Errors(this.backend_errors),
        password: '',
        password_confirmation: '',
        pre_register_token: '',
        name: '',
        email: '',
        min_password_length: 8,
      };
    },

    validations() {
      return {
        password: {
          minLength: minLength(this.min_password_length),
          required,
        },

        password_confirmation: {
          sameAsPassword: sameAs('password'),
          required,
        }
      };
    },

    mounted: function() {
      this.name = this.user.name;
      this.email = this.user.email;
      this.pre_register_token = this.user.pre_register_token;
    },

    methods: {
      inputHasErrors(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'is-invalid';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors.clear(field);
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },
    },
  };
</script>
