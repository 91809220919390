<template>
  <div>
    <div
      v-if="shouldShowAlert"
      :class="alert_info.class"
      role="alert"
    >
      <div class="row">
        <div class="col-12 col-md-9">
          {{ alert_info.message }}
        </div>

        <div
          v-if="!! alert_info.link"
          class="col-12 col-md-3 text-right"
        >
          <div class="d-none d-sm-block">
            <a
              class="btn btn_warning_white btn-sm"
              :href="uri_resend_verification"
            >
              {{ alert_info.link }}
            </a>
          </div>

          <div class="d-block d-sm-none">
            <a
              class="btn btn_warning_white btn-block mt-2"
              :href="uri_resend_verification"
            >
              {{ alert_info.link }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card my-3">
      <div class="card-body">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-8">
            <h3>
              {{ $t('integrations.connect_integration_card.title') }}
            </h3>

            <p class="mb-0">
              {{ $t('integrations.connect_integration_card.description') }}
            </p>
          </div>

          <div class="col-12 col-sm-4 text-right">
            <div class="d-none d-sm-block">
              <SPEButtonWithFeedback
                btn_class="btn btn-primary text-white btn-sm btn_small"
                :btn_text="getButtonTitle"
                :request_pending="request_pending"
                :handle_click="handleClick"
                btn_size="14px"
              />
            </div>

            <div class="d-block d-sm-none">
              <SPEButtonWithFeedback
                btn_class="btn btn-primary text-white btn-block mt-2"
                :btn_text="getButtonTitle"
                :request_pending="request_pending"
                :handle_click="handleClick"
              />
            </div>
          </div>
        </div>

        <SPEWebhooksList
          :webhook="ni_webhook"
          :uri_webhook_show="uri_webhook_show"
        />
      </div>
      <SPEWebhooksTable
        :webhook="ni_webhook"
        :uri_webhook_show="uri_webhook_show"
      />
    </div>
  </div>
</template>

<script>
  import { installIntegration, uninstallIntegration } from '../../api/index';

  import SPEButtonWithFeedback from '../shared/SPEButtonWithFeedback.vue';
  import SPEWebhooksTable from './partials/SPEWebhooksTable.vue';
  import SPEWebhooksList from './partials/SPEWebhooksList.vue';

  import integrations_enum from '../../enum/integrations';

  export default {
    name: 'ConnectIntegration',
    components: {
      SPEButtonWithFeedback,
      SPEWebhooksTable,
      SPEWebhooksList,
    },

    props: {
      webhooks: {
        type: Array,
        default: () => [],
      },

      uri_webhook_show: {
        type: String,
        required: true,
      },

      uri_resend_verification: {
        type: String,
        required: true,
      },

      user: {
        required: true,
        type: Object,
      },
    },

    data() {
      return {
        request_pending: false,
        ni_webhook: [],
        alert_info: {},
      };
    },

    computed: {
      getButtonTitle() {
        if (this.ni_webhook.length === 0) {
          return this.$t('actions.common.connect');
        }

        return this.$t('actions.common.disconnect');
      },

      getUserEmailIsVerified() {
        return !!this.user.email_verified_at;
      },

      getUserHasAdress() {
        return !!this.user.zip_code;
      },

      shouldShowAlert() {
        return !! this.alert_info.message;
      },
    },

    mounted() {
      this.integrationIsConnected();
    },

    methods: {
      async connectIntegration() {
        if (!this.getUserEmailIsVerified) {
          return this.alert_info = {
            class: 'alert alert-warning',
            message: this.$t('integrations.alert.error.connect.email_verified'),
            link: this.$t('actions.common.resend'),
          };
        }

        if (!this.getUserHasAdress) {
          return this.alert_info = {
            class: 'alert alert-warning',
            message: this.$t('integrations.alert.error.connect.address'),
          };
        }

        try {
          this.request_pending = true;
          this.alert_info = {},

          await installIntegration().then((response) => {
            this.request_pending = false;
            this.alert_info = this.getAlertInfo(integrations_enum.install, response.status);
            this.ni_webhook = this.getWebhookInfo(response.data.webhook);
          });
        } catch (err) {
          this.alert_info = this.getAlertInfo(integrations_enum.install, err);
        }
      },

      async disconnectIntegration() {
        if (!this.getUserEmailIsVerified) {
          return this.alert_info = {
            class: 'alert alert-warning',
            message: this.$t('integrations.alert.error.disconnect.email_verified'),
            link: this.$t('actions.common.resend'),
          };
        }

        if (!this.getUserHasAdress) {
          return this.alert_info = {
            class: 'alert alert-warning',
            message: this.$t('integrations.alert.error.disconnect.address'),
          };
        }

        try {
          this.request_pending = true;
          this.alert_info = {},

          await uninstallIntegration().then((response) => {
            this.request_pending = false;
            this.alert_info = this.getAlertInfo(integrations_enum.uninstall, response.status);
            this.ni_webhook = [];
          });
        } catch (err) {
          this.alert_info = this.getAlertInfo(integrations_enum.uninstall, err);
        }
      },

      handleClick() {
        if (this.ni_webhook.length === 0) {
          return this.connectIntegration();
        }

        return this.disconnectIntegration();
      },

      getWebhookInfo(webhook) {
        return {
          id: webhook.id,
          name: webhook.name,
          url: webhook.url,
          created_at: webhook.created_at,
        };
      },

      getAlertInfo(type, status) {
        if (type === integrations_enum.install) {
          if (~~(status / 100) === 2) {
            return {
              class: 'alert alert-success',
              message: this.$t('integrations.alert.connect.success'),
            };
          }

          return {
            class: 'alert alert-danger',
            message: this.$t('integrations.alert.connect.error'),
          };
        }

        if (~~(status / 100) === 2) {
          return {
            class: 'alert alert-success',
            message: this.$t('integrations.alert.disconnect.success'),
          };
        }

        return {
          class: 'alert alert-danger',
          message: this.$t('integrations.alert.disconnect.error'),
        };
      },

      integrationIsConnected() {
        this.webhooks.forEach(webhook => {
          if (webhook.provider_name === integrations_enum.ni_provider) {
            return this.ni_webhook = this.getWebhookInfo(webhook);
          }
        });
      },
    }
  };
</script>

<style scoped>
  .btn_small { min-width: 100px; }

  .btn_warning_white {
    border-color: rgb(241, 198, 21);
    background-color: white;
    color: rgb(208, 173, 31);
  }

  .btn_warning_white:hover {
    border-color: rgb(241, 198, 21);
    background-color: rgb(247, 247, 247);
    color: rgb(208, 173, 31);
  }
</style>
