<template>
  <div class="card packages-average-time">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('packages.delivery_time_title') }}
      </h4>

      <small class="card-text">
        {{ $t('packages.delivery_time_description') }}
      </small>

      <div class="data-container pt-3">
        <div>
          <div v-if="request_pending">
            <ClipLoader color="#f3b008" />
          </div>

          <div v-else>
            <span class="data-content">
              {{ average_time }}
            </span> {{ $t('packages.average_time_description') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  import { fetchAverageTimeToDeliveryMetric } from '../../api';

  export default {
    name: 'PackagesStatusContainer',
    components: { ClipLoader },
    data() {
      return {
        request_pending: false,
        average_time: null,
      };
    },

    async mounted() {
      await this.fetchChartData();
    },

    methods: {
      mountChart(average_time) {
        this.average_time = average_time.value ? String(average_time.value.toFixed(1)).replace('.', ',') : '0';
      },

      async fetchChartData(period) {
        try {
          this.request_pending = true;
          const { data: { data: average_time } } = await fetchAverageTimeToDeliveryMetric(period);

          return this.mountChart(average_time);
        } catch (err) {
          const { response: { status } = {} } = err;

          if (status === 401) {
            this.$emit('show-modal');
          }
        } finally {
          this.request_pending = false;
        }
      },
    },
  };
</script>

<style scoped>
  .packages-status-container{
    grid-row: 6/7;
    grid-column: 1/2;
  }

  .data-content {
    font-weight: bold;
    font-size: 3rem;
  }

  .data-container {
    width: 100%;
    height: 90%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
</style>
