export default {
  'early-1': {
    key: 'early-1',
    name: 'essencial',
    amount: '67,00'
  },

  'early-2': {
    key: 'early-2',
    name: 'super loja',
    amount: '167,00',
  },

  'early-3': {
    key: 'early-3',
    name: 'mega loja',
    amount: '267,00',
  },

  'freemium': {
    key: 'freemium',
    name: 'grátis',
    amount: '0,00'
  },
};
