<template>
  <section class="row bg-primary">
    <div class="col-12">
      <div class="card-row d-flex flex-column justify-content-center align-items-center tracking-table-container">
        <div class="card col-12 col-sm-6 p-0 mt-3">
          <div class="card-body">
            <h3 class="card-title">
              Notificações canceladas
            </h3>

            <p>
              A partir de agora não iremos mais te atualizar sobre o andamento de seus pacotes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: 'OptOutConfirmed',
  };
</script>
