<template>
  <div
    :class="{
      'expanded-area': !getHideAttribute,
      'minimized-area' : getHideAttribute,
    }"
    class="table-data-container mb-0"
  >
    <span>
      {{ getTextValue }}
    </span>

    <small>
      <button
        v-if="data_text.length > 100"
        class="btn-small sm-col-1 container-button"
        @click="toggleHideText"
      >
        {{ getButtonLabel }}
      </button>
    </small>
  </div>
</template>

<script>
  import truncate from 'lodash/truncate';

  export default {
    name: 'TableDataContainer',
    props: {
      data_text: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        hide_text: true,
      };
    },

    computed: {
      getTextValue() {
        if (this.hide_text) {
          return truncate(this.data_text, {
            length: 100,
          });
        }

        return this.data_text;
      },

      getButtonLabel() {
        if (this.hide_text) {
          return 'Expandir';
        }

        return 'Minimizar';
      },

      getHideAttribute() {
        return this.hide_text;
      }
    },

    methods: {
      truncate,
      toggleHideText() {
        this.hide_text = !this.hide_text;
      },
    },
  };
</script>

<style scoped>
  .table-data-container {
    position: relative;
    transition: all 1s;
    overflow: hidden;
  }

  .minimized-area { max-height: 150px; }
  .expanded-area { max-height: 200vh; }
  .container-button {
    border: none !important;
    outline: none !important;
    background: none !important;
    color: #3a22e5;
  }
</style>
