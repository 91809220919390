<template>
  <div>
    <div class="card my-3">
      <div class="card-body">
        <h3 class="card-title">
          Método de pagamento
        </h3>

        <dl class="row text-md-center mt-3">
          <div class="col-12 col-md-6">
            <dt>Últimos 4 dígitos do cartão</dt>
            <dd>
              {{ user.pm_last_four || "N/A" }}
            </dd>
          </div>

          <div class="col-12 col-md-6">
            <dt>Bandeira do cartão</dt>
            <dd>
              {{ user.pm_type || "N/A" }}
            </dd>
          </div>
        </dl>

        <form
          id="paymentMethodForm"
          :action="payment_method_update_uri"
          method="POST"
          @submit.prevent="submitForm"
        >
          <div class="row justify-content-center mt-4">
            <div class="col-12 col-md-6 text-center">
              <input
                type="hidden"
                name="_token"
                :value="csrf_token"
              />

              <div class="card p-2">
                <div ref="card" />
              </div>

              <span
                v-if="stripeError"
                class="small text-danger"
              >
                <strong>
                  {{ translateStripeError(stripeError) }}
                </strong>
              </span>
            </div>
          </div>

          <div class="row text-right">
            <div class="col-12 col-md-4 mt-4 ml-auto">
              <SPEButtonWithFeedback
                btn_class="btn btn-primary btn-block text-white"
                btn_type="submit"
                btn_text="Adicionar método de pagamento"
                :request_pending="requestPending"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card my-3">
      <div class="card-body">
        <div class="row justify-content-between">
          <div class="col-12">
            <h3>FAQ - Dúvidas Frequentes</h3>

            <div class="faq-item mt-3">
              <h6>{{ $t('settings.faq.safe-environment.doubt') }}</h6>

              <p>
                {{ $t('settings.faq.safe-environment.solution') }}

                <a
                  href="https://stripe.com/en-br"
                  target="_blank"
                >
                  Stripe.
                </a>
              </p>
            </div>

            <hr />

            <div class="faq-item mt-3">
              <h6>{{ $t('settings.faq.no-credit-card.doubt') }}</h6>

              <p>
                {{ $t('settings.faq.no-credit-card.solution') }}

                <a
                  href="https://contasimples.com/"
                  target="_blank"
                >
                  ContaSimples.
                </a>
              </p>
            </div>

            <hr />

            <div class="faq-item mt-3">
              <h6>{{ $t('settings.faq.flags-accepted.doubt') }}</h6>

              <p>{{ $t('settings.faq.flags-accepted.solution') }}</p>
            </div>

            <hr />

            <div class="faq-item mt-3">
              <h6>{{ $t('settings.faq.charges.doubt') }}</h6>

              <p>{{ $t('settings.faq.charges.solution') }}</p>
            </div>

            <hr />

            <div class="faq-item mt-3">
              <h6>{{ $t('settings.faq.signature.doubt') }}</h6>

              <p>{{ $t('settings.faq.signature.solution') }}</p>
            </div>

            <hr />

            <div class="faq-item mt-3">
              <h6>{{ $t('settings.faq.invoices.doubt') }}</h6>

              <p>{{ $t('settings.faq.invoices.solution') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SPEButtonWithFeedback from '../shared/SPEButtonWithFeedback.vue';

  export default {
    name: 'PaymentMethodEdit',
    components: { SPEButtonWithFeedback },
    props: {
      csrf_token: {
        required: true,
        type: String,
      },

      client_secret: {
        required: true,
        type: String,
      },

      stripe_token: {
        required: true,
        type: String,
      },

      payment_method_update_uri: {
        required: true,
        type: String,
      },

      user: {
        required: true,
        type: Object,
      },
    },

    data() {
      return {
        cardToken: null,
        stripeError: null,
        requestPending: false,
        stripe: null,
        card: null,
      };
    },

    created() {
      this.mountStripeCard();
    },

    methods: {
      async submitForm() {
        this.requestPending = true;

        const { setupIntent, error } = await this.stripe.confirmCardSetup(this.client_secret, {
          payment_method: {
            card: this.card,
          }
        });

        if (error) {
          return this.handleError(error);
        }

        return this.handleSuccess(setupIntent.payment_method);
      },

      handleSuccess(token) {
        let form = document.getElementById('paymentMethodForm');
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'stripe_token');
        input.setAttribute('value', token);

        form.appendChild(input);
        form.submit();
      },

      handleError (error) {
        this.requestPending = false;
        this.stripeError = error.message;
      },

      translateStripeError(message) {
        return this.$t(message);
      },

      mountStripeCard() {
        this.stripe = Stripe($('meta[name="stripe"]').attr('content'));
        const elements = this.stripe.elements();

        this.$nextTick().then(() => {
          this.card = elements.create('card', {
            hidePostalCode: true,
          });

          this.card.mount(this.$refs.card);
        });
      }
    },
  };
</script>

<style scoped>
.faq-item {
  margin: 2px 0 0 0;
  width: 100%;
}

.faq-item h6 {
  font-size: 1rem;
  font-weight: 600;
  margin: 3px 0;
}

.faq-item p {
  margin-bottom: 0;
}
</style>
