<template>
  <div
    v-if="webhook.length !== 0"
    class="d-block d-sm-none"
  >
    <hr />

    <ul class="list-unstyled m-0">
      <li class="py-1">
        <strong>
          {{ $t('fields.common.name') }}
        </strong>
        {{ webhook.name }}
      </li>

      <li class="py-1">
        <strong>
          {{ $t('fields.common.url') }}
        </strong>

        {{ webhook.url }}
      </li>

      <li class="py-1">
        <strong>
          {{ $t('fields.common.created_at') }}
        </strong>

        {{ getWebhookFormattedDate }}
      </li>

      <li class="py-1">
        <a
          class="btn btn-outline-success btn-block"
          :href="getWebhookRoute"
        >
          {{ $t('actions.common.show') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'WebhooksList',
    props: {
      webhook: {
        type: [Array, Object],
        default: () => {},
      },

      uri_webhook_show: {
        type: String,
        required: true,
      }
    },

    computed: {
      getWebhookFormattedDate() {
        return moment(this.webhook.created_at).format('DD/MM/YYYY');
      },

      getWebhookRoute() {
        return this.uri_webhook_show.replace('webhook_id', this.webhook.id);
      },
    }
  };
</script>
