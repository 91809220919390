<template>
  <div class="card optout-container">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('packages.opt_outs_title') }}
      </h4>

      <small class="card-text">
        {{ $t('packages.opt_outs_descrption') }}
      </small>

      <div
        v-if="request_pending"
        class="pt-5"
      >
        <ClipLoader color="#f3b008" />
      </div>

      <div
        v-else
        class="pt-3"
      >
        <div v-if="!renderChart">
          <hr />

          <p class="card-text">
            {{ $t('packages.without_opt_outs') }}
          </p>
        </div>

        <SPELineChart
          v-if="chart_data.labels.length > 0"
          :style="graphStyle"
          :chart-data="chart_data"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  import SPELineChart from './charts/SPELineChart.vue';

  import { fetchOptOuts } from '../../api';

  export default {
    name: 'OptOutReports',
    components: {
      ClipLoader,
      SPELineChart,
    },

    data() {
      return {
        request_pending: false,
        chart_data: {
          labels: [],
          datasets: [],
        },
      };
    },

    computed: {
      renderChart() {
        return this.chart_data.labels.length > 0;
      },

      graphStyle() {
        return {
          position: 'relative',
          height: '170px',
        };
      },
    },

    async mounted() {
      await this.fetchChartData();
    },

    methods: {
      async fetchChartData(period){
        try {
          this.request_pending = true;
          const { data: { data } } = await fetchOptOuts(period);

          return this.mountChart(data);

        } catch (err) {
          const { response: { status } = {} } = err;
          this.$emit('show-modal');
          if (status === 401) {
            this.$emit('show-modal');
          }
        } finally {
          this.request_pending = false;
        }
      },

      mountChart(data){
        const backgroundColor = 'rgba(0,0,0,0)';
        const borderWidth = 1;
        const pointStyle = 'rect';

        const opt_out_data = {
          label: 'Contatos bloqueados por dia',
          data: [],
          borderColor: '#f3b008',
          backgroundColor,
          borderWidth,
          pointStyle,
          pointBackgroundColor: '#f3b008',
        };

        const opt_out_labels = [];

        data.forEach(element => {
          opt_out_labels.push(element.label);
          opt_out_data.data.push(element.value);
        });

        this.chart_data.datasets[0] = opt_out_data;
        this.chart_data.labels = opt_out_labels;
      }
    },
  };
</script>

<style scoped>
  .optout-container {
    grid-row: 5/7;
    grid-column: 3/5;
  }
</style>
