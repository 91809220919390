<template>
  <div class="card gauge-container">
    <div class="card-body">
      <h4 class="card-title mb-0">
        Capacidade do plano
      </h4>

      <small class="card-text">
        Aqui você pode visualizar o total de pacotes monitorados atualmente e o quanto falta para atingir sua franquia.
      </small>

      <div v-if="request_pending">
        <ClipLoader color="#f3b008" />
      </div>

      <div
        v-else
        class="pt-3"
      >
        <SPEGaugeChart
          :max_capacity="max_capacity"
          :min_capacity="min_capacity"
          :used_capacity="used_capacity"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  import SPEGaugeChart from './charts/SPEGaugeChart.vue';

  import { fetchPlanCapacityMetric } from '../../api';

  export default {
    name: 'PlanCapacityGaugeContainer',
    components: {
      ClipLoader,
      SPEGaugeChart,
    },

    data() {
      return {
        max_capacity: null,
        min_capacity: null,
        used_capacity: null,
        request_pending: false,
      };
    },

    computed: {
      renderChart() {
        return !!this.used_capacity;
      },
    },

    async mounted() {
      try {
        this.request_pending = true;
        const { data: { data } } = await fetchPlanCapacityMetric();

        this.used_capacity = data[0].value;
        this.max_capacity = data[1].value;
        this.min_capacity = data[2].value;
      } catch (err) {
        const { response: { status } = {} } = err;

        if (status === 401) {
          this.$emit('show-modal');
        }
      } finally {
        this.request_pending = false;
      }
    }
  };
</script>

<style scoped>
  .gauge-container {
    grid-row: 2/4;
    grid-column: 1/2;
  }
</style>
