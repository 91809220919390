import axios from 'axios';
import moment from 'moment';
import '../config/axios';

import packages_status from '../static/packages_status';

const fetchPackagesPerMonthMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
    'aggregate_by': 'month',
  });

  const metrics = await axios.get('/metrics/packages/per-month', { params: url_params });

  return metrics;
};

const fetchPlanCapacityMetric = async function() {
  const capacity = await axios.get('/metrics/packages/plan-capacity-used');

  return capacity;
};

const fetchPackagesPerCarrierMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
  });

  const carriers = await axios.get('/metrics/packages/per-carrier', {
    params: url_params,
  });

  return carriers;
};

const fetchPackagesInProgressMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
  });

  const packages = await axios.get('/metrics/packages/in-progress', {
    params: url_params,
  });

  return packages;
};

const fetchPackagesWithoutEventsMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
  });

  const packages = await axios.get('/metrics/packages/without-events', {
    params: url_params,
  });

  return packages;
};

const fetchMailNotificationsSentAndDeliveredMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
    'aggregate_by': 'day',
  });

  const [notifications_sent, notifications_delivered] = await Promise.all([
    axios.get('/metrics/emails/sent-per-day', { params: url_params }),
    axios.get('/metrics/emails/delivered-per-day', { params: url_params }),
  ]);

  return {
    notifications_sent,
    notifications_delivered,
  };
};

const fetchRefusedPackagesMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
  });

  const packages = axios.get('/metrics/packages/refused', {
    params: url_params,
  });

  return packages;
};

const fetchAverageTimeToDeliveryMetric = async function(params) {
  const default_params = {
    end_date: moment().format('X'),
    start_date: moment().subtract(30, 'days').format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
  });

  const days = axios.get('/metrics/packages/average-time-to-delivery', {
    params: url_params,
  });

  return days;
};

const fetchSMSNotificationsSentAndDeliveredMetric = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
    'aggregate_by': 'day',
  });

  const [sms_sent, sms_delivered] = await Promise.all([
    axios.get('/metrics/sms/sent-per-day', { params: url_params }),
    axios.get('/metrics/sms/delivered-per-day', { params: url_params }),
  ]);

  return {
    sms_sent,
    sms_delivered,
  };
};

const fetchPackages = async function(params) {
  const { package_status: package_status_filter } = params;

  const default_params = {
    customer_name: '',
    description: '',
    include: 'latest_event',
    issued: false,
    latest_event: '',
    limit: 30,
    page: 1,
    shipping_company: '',
    tracking_code: '',
  };

  const {
    customer_name,
    description,
    include,
    issued,
    latest_event,
    limit,
    page,
    shipping_company,
    tracking_code,
  } = { ...default_params, ...params };

  const url_params = new URLSearchParams({
    'filter[customer_name]': customer_name,
    'filter[description]': description,
    'filter[latest_event]': latest_event,
    'filter[shipping_company]': shipping_company,
    'filter[tracking_code]': tracking_code,
    'include': include,
    'limit': limit,
    'page': page,
  });

  if (package_status_filter === packages_status.finished) {
    url_params.append('filter[finished]', true);
  }

  if (package_status_filter === packages_status.in_progress) {
    url_params.append('filter[finished]', false);
    url_params.append('filter[issued]', false);
  }

  if (package_status_filter === packages_status.issued) {
    url_params.append('filter[issued]', true);
  }

  if (package_status_filter === packages_status.without_events) {
    url_params.append('filter[without_events]', true);
  }

  const { data } = await axios.get('/packages', { params: url_params });

  return data;
};

const fetchEvents = async function(params) {
  const default_params = {
    unique_description: true,
    events: 'description',
  };

  const { unique_description, events } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'fields[events]': events,
    'filter[unique_description]': unique_description,
  });

  const { data } = await axios.get('/events', { params: url_params });

  return data;
};

const fetchOptOuts = async function(params) {
  const default_params = {
    start_date: moment().subtract(30, 'days').format('X'),
    end_date: moment().format('X'),
  };

  const { start_date, end_date } = { ...default_params, ...params };
  const url_params = new URLSearchParams({
    'start_date': start_date,
    'end_date': end_date,
    'aggregate_by': 'day',
  });

  const metrics = await axios.get('/metrics/opt-outs/per-day', { params: url_params });

  return metrics;
};

const installIntegration = async function() {
  const response = await axios.post('/integrations');

  const { data: { data }, status } = response;

  return { data, status };
};

const uninstallIntegration = async function() {
  const response = await axios.delete('/integrations');

  const { data: { data }, status } = response;

  return { data, status };
};

export {
  fetchAverageTimeToDeliveryMetric,
  fetchEvents,
  fetchMailNotificationsSentAndDeliveredMetric,
  fetchOptOuts,
  fetchPackages,
  fetchPackagesInProgressMetric,
  fetchPackagesPerCarrierMetric,
  fetchPackagesPerMonthMetric,
  fetchPackagesWithoutEventsMetric,
  fetchPlanCapacityMetric,
  fetchRefusedPackagesMetric,
  fetchSMSNotificationsSentAndDeliveredMetric,
  installIntegration,
  uninstallIntegration,
};
