import Vue from 'vue';
import { i18n } from './plugins/i18n';
import * as Integrations from '@sentry/integrations';
import * as Sentry from '@sentry/browser';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Clipboard from 'v-clipboard';
import moment from 'moment';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import 'vue-json-viewer/style.css';

import GPMBanner from 'npm-gate-banner/src/GPMBanner.vue';
import SPECopyButton from './components/shared/SPECopyButton.vue';
import SPESentryConfigureScope from './components/shared/SPESentryConfigureScope.vue';
import SPETableDataContainer from './components/shared/SPETableDataContainer.vue';
import SPELoginForm from './components/auth/SPELoginForm.vue';
import SPEPreRegisterForm from './components/auth/SPEPreRegisterForm.vue';
import SPERecoverForm from './components/auth/SPERecoverForm.vue';
import SPERegisterForm from './components/auth/SPERegisterForm.vue';
import SPEResetPasswordForm from './components/auth/SPEResetPasswordForm.vue';
import SPEPackagesIndex from './components/packages/SPEPackagesIndex.vue';
import SPEImportPackages from './components/packages/SPEImportPackages.vue';
import SPEOptOutConfirmation from './components/opt-out/SPEOptOutConfirmation.vue';
import SPEOptOutConfirmed from './components/opt-out/SPEOptOutConfirmed.vue';
import SPEOptOutCreate from './components/opt-out/SPEOptOutCreate.vue';
import SPEPrettyJson from './components/shared/SPEPrettyJson.vue';
import SPEDashboardContainer from './components/dashboard/SPEDashboardContainer.vue';
import SPEConnectIntegration from './components/settings/SPEConnectIntegration.vue';
import SPEPaymentMethodEdit from './components/settings/SPEPaymentMethodEdit.vue';
import SPEShowPackagePhoneInput from './components/packages/partials/SPEShowPackagePhoneInput.vue';
import SPEPlansBanner from './components/settings/SPEPlansBanner.vue';
import UsersEditAddressForm from 'packages-toolbox-users/resources/js/components/users/UsersEditAddressForm.vue';
import UsersEditProfileForm from 'packages-toolbox-users/resources/js/components/users/UsersEditProfileForm.vue';
import UsersAddress from 'packages-toolbox-users/resources/js/components/users/UsersAddress.vue';
import UsersProfile from 'packages-toolbox-users/resources/js/components/users/UsersProfile.vue';

// Shared Components
Vue.component('spe-copy-button', SPECopyButton);
Vue.component('spe-sentry-configure-scope', SPESentryConfigureScope);
Vue.component('spe-table-data-container', SPETableDataContainer);

// Auth Components
Vue.component('spe-login-form', SPELoginForm);
Vue.component('spe-pre-register-form', SPEPreRegisterForm);
Vue.component('spe-recover-form', SPERecoverForm);
Vue.component('spe-register-form', SPERegisterForm);
Vue.component('spe-reset-password-form', SPEResetPasswordForm);

// Packages Components
Vue.component('spe-packages-index', SPEPackagesIndex);
Vue.component('spe-import-packages', SPEImportPackages);
Vue.component('spe-show-package-input-phone', SPEShowPackagePhoneInput);

// Opt-out Components
Vue.component('spe-opt-out-confirmation', SPEOptOutConfirmation);
Vue.component('spe-opt-out-confirmed', SPEOptOutConfirmed);
Vue.component('spe-opt-out-create', SPEOptOutCreate);

// Dashboard Components
Vue.component('spe-pretty-json', SPEPrettyJson);
Vue.component('spe-dashboard-container', SPEDashboardContainer);

// Settings
Vue.component('spe-connect-integration', SPEConnectIntegration);
Vue.component('spe-payment-method-edit', SPEPaymentMethodEdit);
Vue.component('spe-plans-banner', SPEPlansBanner);

// Toolbox Users Components
Vue.component('gpmp-users-edit-address-form', UsersEditAddressForm);
Vue.component('gpmp-users-edit-profile-form', UsersEditProfileForm);
Vue.component('gpmp-users-address', UsersAddress);
Vue.component('gpmp-users-profile', UsersProfile);

// Private Modules
Vue.component('gpm-banner', GPMBanner);

Chart.plugins.unregister(ChartDataLabels);

Vue.use(Vuelidate);
Vue.use(Clipboard);
Vue.use(VueTheMask);

moment.locale('pt-BR');

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  environment: APP_ENV,
  beforeSend(event, hint) {
    if (hint.originalException.message === window.cancelMessage) {
      return null;
    }

    return event;
  },
});

const app = new Vue({
  el: '#app',
  i18n,
});
