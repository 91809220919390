<template>
  <SPEModal
    ref="modal"
    :has_action_button="has_action_button"
    @callback="closeModal"
  >
    <template slot="header">
      Cancelar plano
    </template>

    <p>
      Tem certeza que deseja cancelar seu plano atual? <br />
      Ao fim do ciclo de cobrança do plano atual migraremos sua conta para a conta GRÁTIS, até lá você manterá todos os benefícios do plano atual.
    </p>

    <template slot="action-btn">
      <SPEButtonWithFeedback
        btn_class="btn btn-danger col-12 col-sm-4"
        btn_text="Cancelar plano"
        :request_pending="request_pending"
        :handle_click="unsubscribe"
      />
    </template>

    <template
      slot="dismiss-modal-text"
    >
      Continuar com o plano
    </template>
  </SPEModal>
</template>

<script>
  import SPEModal from '../../shared/SPEModal.vue';
  import SPEButtonWithFeedback from '../../shared/SPEButtonWithFeedback.vue';

  export default {
    name: 'DowngradeConfirmationModal',
    components: {
      SPEModal,
      SPEButtonWithFeedback,
    },

    data() {
      return {
        has_action_button: true,
        request_pending: false,
      };
    },

    methods: {
      showModal() {
        this.$refs.modal.showModal = true;
      },

      closeModal() {
        this.$refs.modal.showModal = false;
      },

      unsubscribe() {
        this.request_pending = true;
        this.$emit('unsubscribe');
      }
    }
  };
</script>
