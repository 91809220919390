<template>
  <div class="card my-3">
    <div class="card-body">
      <h3 class="card-title">
        {{ $t('fields.packages.import.title') }}
      </h3>

      <p class="card-text">
        {{ $t('fields.packages.import.subtitle') }}
        <a
          :href="manual_url"
          target="_blank"
        >
          {{ $t('actions.common.click_here') }}
        </a>
      </p>

      <hr />

      <form
        method="POST"
        :action="link_import"
        enctype="multipart/form-data"
        @submit="submitForm"
      >
        <input
          type="hidden"
          name="_token"
          :value="csrf_token"
        />

        <div class="custom-file">
          <input
            id="excel_file"
            name="file"
            type="file"
            accept=".csv,application/csv,text/csv,text/plain,application/vnd.ms-excel"
            @change="changeLabel"
          />
        </div>

        <div class="row ml-1">
          <input
            class="form-control col-10 col-md-5 mr-1"
            :class="inputHasErrors('file_label')"
            placeholder="Selecione o arquivo"
            :value="file_label"
            @click="selectArchive"
          />

          <button
            type="button"
            class="btn btn-primary"
            @click="selectArchive"
          >
            <i class="fas fa-file-upload text-white" />
          </button>
        </div>


        <div>
          <span
            v-if="!$v.file_label.supportedFormatter && $v.file_label.$error"
            class="text-danger font-weight-bold text-small"
          >
            {{ $t('fields.packages.import.invalid') }}
          </span>

          <span
            v-else-if="!$v.file_label.required && $v.file_label.$error"
            class="text-danger font-weight-bold text-small"
          >
            {{ $t('fields.packages.import.missing_archive') }}
          </span>

          <span
            v-for="(error, index) in errors.errors"
            :key="index"
            class="text-danger font-weight-bold text-small"
          >
            {{ error[0] }}
          </span>
        </div>

        <div class="row text-right">
          <div class="col-12 mt-3 ml-auto">
            <button
              type="submit"
              class="btn btn-primary text-white"
            >
              {{ $t('actions.import_packages.import') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators';

  import Errors from '../../domain/Errors';

  const supportedFormatter = (value) => {
    const archive = value.split('.');
    const availableFormatters = ['csv'];
    let available = -1;

    archive.forEach((item) => {
      available = availableFormatters.indexOf(item);
    });

    if (available >= 0 || !value){
      return true;
    }

    return false;
  };

  export default {
    name: 'ImportPackages',

    props: {
      csrf_token: {
        type: String,
        required: true,
      },

      link_import: {
        type: String,
        required: true,
      },

      manual_url: {
        type: String,
        required: true,
      },

      backend_errors: {
        type: [Array, Object],
        required: true,
      },
    },

    validations() {
      return {
        file_label: {
          required,
          supportedFormatter,
        },
      };
    },

    data() {
      return {
        file_label: '',
        errors: new Errors(this.backend_errors),
      };
    },

    methods: {
      inputHasErrors(field) {
        if (this.$v[field].$error || Object.keys(this.backend_errors).length > 0) {
          return 'is-invalid';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        Object.keys(this.errors.errors).forEach(error => {
          this.errors.clear(error);
        });
      },

      selectArchive () {
        let input_file = document.querySelector('#excel_file');
        input_file.click();
      },

      changeLabel() {
        let input_file = document.querySelector('#excel_file');
        this.file_label = input_file.files[0].name;
        this.clearFeedbackErrors('file_label');
        this.$v.$touch();
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },
    },
  };
</script>

<style scoped>
  .custom-file {
    display: none;
  }
</style>
