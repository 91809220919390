<template>
  <div class="card mail-notifications-container">
    <div class="card-body">
      <h4 class="card-title">
        Notificações por e-mail enviadas e entregues
      </h4>

      <small class="card-text">
        Aqui você pode visualizar quantas notificações foram enviadas por e-mail e quantas foram entregues para seus clientes.
      </small>

      <div class="chart-line-container">
        <div
          v-if="request_pending"
          class="pt-5"
        >
          <ClipLoader color="#f3b008" />
        </div>
        <div
          v-else
          class="pt-3"
        >
          <div v-if="!renderChart">
            <hr />

            <p class="card-text">
              Não há dados para serem exibidos aqui
            </p>
          </div>

          <SPELineChart
            v-if="chart_data.labels.length > 0"
            :style="graphStyle"
            :chart-data="chart_data"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import _ from 'lodash';
  import moment from 'moment';

  import SPELineChart from './charts/SPELineChart.vue';

  import { fetchMailNotificationsSentAndDeliveredMetric } from '../../api';

  export default {
    name: 'MailNotificationsSentAndDeliveredChartContainer',
    components: {
      ClipLoader,
      SPELineChart,
    },

    data() {
      return {
        request_pending: false,

        chart_data: {
          labels: [],
          datasets: [],
        },
      };
    },

    computed: {
      renderChart() {
        return this.chart_data.labels.length > 0;
      },

      graphStyle() {
        return {
          position: 'relative',
          height: '170px',
        };
      },
    },

    async mounted() {
      await this.fetchChartData();
    },

    methods: {
      mountChart(data) {
        const {
          notifications_sent: {
            data: {
              data: notifications_sent,
            },
          },
          notifications_delivered: {
            data: {
              data : notifications_delivered,
            },
          }
        } = data;

        const chart_labels = _(notifications_sent).map('label')
          .map(period => {
            return moment(period, 'DD/MM/YYYY')
              .format('L')
              .toUpperCase();
          }).value();

        const backgroundColor = 'rgba(0,0,0,0)';
        const borderWidth = 1;
        const pointStyle = 'rect';

        const notifications_sent_per_day_data = _.map(notifications_sent, 'value');
        const notifications_delivered_per_day_data = _.map(notifications_delivered, 'value');

        const notifications_sent_per_day = {
          label: 'Notificações por e-mail enviadas por dia',
          data: notifications_sent_per_day_data,
          backgroundColor,
          borderColor: '#f3b008',
          borderWidth,
          pointBackgroundColor: '#f3b008',
          pointStyle,
        };

        const notifications_delivered_per_day = {
          label: 'Notificações por e-mail entregues por dia',
          data: notifications_delivered_per_day_data,
          backgroundColor,
          borderColor: '#3a22e5',
          borderWidth,
          pointBackgroundColor: '#3a22e5',
          pointStyle,
        };

        this.chart_data.labels = chart_labels;
        this.chart_data.datasets[0] = notifications_sent_per_day;
        this.chart_data.datasets[1] = notifications_delivered_per_day;
      },

      async fetchChartData(period) {
        try {
          this.request_pending = true;
          const response = await fetchMailNotificationsSentAndDeliveredMetric(period);

          return this.mountChart(response);
        } catch (err) {
          const { response: { status } = {} } = err;

          if (status === 401) {
            this.$emit('show-modal');
          }
        } finally {
          this.request_pending = false;
        }
      },
    },
  };
</script>

<style scoped>
  .chart-line-container { min-height: 150px; }
  .mail-notifications-container {
    grid-row: 3/5;
    grid-column: 3/5;
  }
</style>
