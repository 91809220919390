<template>
  <div class="mt-md-3 d-flex justify-content-between align-items-center">
    <div
      v-for="(product_config, product_name) in products"
      :key="product_name"
      :class="getCardStyle(product_config, product_name)"
      class="card"
    >
      <template v-if="!product_config.hidden">
        <h4
          :class="`bg-${product_config.background}`"
          class="header text-center text-white text-uppercase"
        >
          {{ translateProductName(product_name) }}
        </h4>

        <div class="card-body text-center">
          <div :class="`text-${product_config.background}`">
            <span class="h2 font-weight-bold">
              R$ {{ product_config.amount }}
            </span>

            <small>/ MÊS</small>
          </div>

          <form
            :ref="`${product_name}Form`"
            :action="subscription_route"
            method="POST"
          >
            <input
              type="hidden"
              name="_token"
              :value="csrf_token"
            />

            <input
              type="hidden"
              name="product_name"
              :value="product_name"
            />

            <button
              :class="`btn-${product_config.background}`"
              :disabled="buttonShouldBeDisabled(product_name)"
              class="btn col-12 mt-3 text-white"
              type="button"
              @click="handleClick(product_name)"
            >
              {{ getButtonLabel(product_name) }}
            </button>
          </form>

          <ul
            :class="getFeatureStyles(product_name)"
            class="list-unstyled features text-left d-block mt-3 mb-0"
          >
            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i class="fas fa-check-double text-success mr-1" />
              {{ packagesLimitOfProduct(product_config) }}
            </li>

            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i class="fas fa-check-double text-success mr-1" />
              {{ getStringFromLanguage('email') }}
            </li>

            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i :class="`fas ${ product_config.sms ? 'fa-check-double text-success' : 'fa-times text-danger' } mr-1`" />
              {{ smsLimitOfProduct(product_config) }}
            </li>

            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i class="fas fa-check-double text-success mr-1" />
              {{ getStringFromLanguage('tracking') }}
            </li>

            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i class="fas fa-check-double text-success mr-1" />
              {{ getStringFromLanguage('integrations') }}
            </li>

            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i class="fas fa-check-double text-success mr-1" />
              {{ getStringFromLanguage('webhooks') }}
            </li>

            <li :class="product_name === productsStatic['early-2'].key ? 'my-2' : 'my-1'">
              <i class="fas fa-check-double text-success mr-1" />
              {{ getStringFromLanguage('advanced_reports') }}
            </li>
          </ul>
        </div>
      </template>
    </div>

    <SPEDowngradeConfirmationModal
      ref="downgradeModal"
      @unsubscribe="continueDowngrade"
    />

    <SPEConfirmSubscribeModal
      ref="subscribeModal"
      :modal_product_name="modal_product_name"
      :modal_price_amount="modal_price_amount"
      :subscribe_product="subscribe_product"
      @subscribe="continueSubscribe"
    />
  </div>
</template>

<script>
  import SPEConfirmSubscribeModal from './partials/SPEConfirmSubscribeModal.vue';
  import SPEDowngradeConfirmationModal from './partials/SPEDowngradeConfirmationModal.vue';

  import productsStatic from '../../static/products';
  import productsEnum from '../../enum/products';

  export default {
    name: 'PlansBanner',
    components: {
      SPEConfirmSubscribeModal,
      SPEDowngradeConfirmationModal,
    },

    props: {
      products: {
        type: Object,
        required: true,
      },

      csrf_token: {
        type: String,
        required: true,
      },

      subscription_route: {
        type: String,
        required: true,
      },

      cancel_subscription_route: {
        type: String,
        required: true,
      },

      user_product_name: {
        type: String,
        required: true,
      },

      user_on_grace_period: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        productsEnum,
        productsStatic,
        modal_product_name: '',
        modal_price_amount: '',
        request_pending: false,
        subscribe_product: '',
      };
    },

    methods: {
      getCardStyle(product_config, product_name) {
        if (this.productsStatic['early-2'].key === product_name) {
          return `border-${product_config.background} featured-card`;
        }

        return `border-${product_config.background} small-card`;
      },

      getFeatureStyles(product_name) {
        if (this.productsStatic['early-2'].key === product_name) {
          return '';
        }
      },

      getStringFromLanguage(key) {
        return this.productsEnum[key];
      },

      translateProductName(product_name) {
        return this.productsStatic[product_name].name;
      },

      packagesLimitOfProduct(product_config) {
        return this.getStringFromLanguage('packages').replace(':packages_number', product_config.packages);
      },

      smsLimitOfProduct(product_config) {
        if (product_config.sms) {
          return this.getStringFromLanguage('sms').replace(':sms_number', product_config.packages);
        }

        return 'Sem envio de SMS';
      },

      getButtonLabel(product_name) {
        if (this.selectedProductNameIsEqualActualProduct(product_name)) {
          return this.getStringFromLanguage('actual_plan');
        }

        return this.getStringFromLanguage('subscribe_now');
      },

      selectedProductNameIsEqualActualProduct(product_name) {
        return this.user_product_name === product_name;
      },

      buttonShouldBeDisabled(product_name) {
        if (this.user_on_grace_period && product_name === this.productsStatic['freemium'].key) {
          return true;
        }

        return this.selectedProductNameIsEqualActualProduct(product_name);
      },

      ribbonLabelShouldRender(product_name) {
        return product_name === this.productsStatic['early-2'].key;
      },

      handleClick(product_name) {
        if (product_name === this.productsStatic['freemium'].key) {
          return this.$refs.downgradeModal.showModal();
        }

        this.modal_product_name = this.productsStatic[product_name].name.toUpperCase();
        this.modal_price_amount = this.productsStatic[product_name].amount;
        this.subscribe_product = product_name;

        return this.$refs.subscribeModal.showModal();
      },

      continueDowngrade() {
        return window.location.href = this.cancel_subscription_route;
      },

      continueSubscribe(product_name) {
        return this.$refs[`${product_name}Form`][0].submit();
      },
    }
  };
</script>

<style scoped>
  .featured-card {
    max-width: 300px;
    height: 100%;
  }

  .small-card {
    max-width: 250px;
    height: 90%;
  }
</style>
