<script>
  import { Pie, mixins } from 'vue-chartjs';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  const { reactiveProp } = mixins;

  export default {
    name: 'PieChart',
    extends: Pie,
    mixins: [reactiveProp],
    data() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'bottom',
          },

          plugins: {
            datalabels: {
              color: '#fff',
              position: 'bottom',
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                  sum += data;
                });

                let percentage = ((value * 100 / sum).toFixed(2) + '%').replace('.', ',');

                return percentage;
              },

              font: {
                weight: 'bold',
                size: '14',
              },
            },
          },
        },
      };
    },

    mounted() {
      this.addPlugin(ChartDataLabels);
      this.renderChart(this.chartData, this.options);
    },
  };
</script>
