<template>
  <div
    v-if="webhook.length !== 0"
    class="d-none d-sm-block"
  >
    <table class="table mb-0">
      <thead>
        <tr>
          <th>{{ $t('fields.common.name') }}</th>
          <th>{{ $t('fields.common.url') }}</th>
          <th>{{ $t('fields.common.created_at') }}</th>
          <th />
        </tr>
      </thead>

      <tbody class="table table-hover">
        <tr>
          <td
            width="30%"
            class="align-middle"
          >
            {{ webhook.name }}
          </td>
          <td
            width="30%"
            class="align-middle"
          >
            {{ webhook.url }}
          </td>

          <td class="align-middle">
            {{ getWebhookFormatedDate }}
          </td>

          <td class="text-right pr-3">
            <a
              class="btn btn-outline-success btn-sm"
              :href="getWebhookRoute"
            >
              {{ $t('actions.common.show') }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'WebhooksTable',
    props: {
      webhook: {
        type: [Array, Object],
        default: () => {},
      },

      uri_webhook_show: {
        type: String,
        required: true,
      }
    },

    computed: {
      getWebhookFormatedDate() {
        return moment(this.webhook.created_at).format('DD/MM/YYYY');
      },

      getWebhookRoute() {
        return this.uri_webhook_show.replace('webhook_id', this.webhook.id);
      },
    }
  };
</script>
