<template>
  <SPEModal
    ref="modal"
    :has_action_button="has_action_button"
    @callback="closeModal"
  >
    <template slot="header">
      Confirmar assinatura
    </template>

    <p>
      Atenção você está assinando o plano {{ modal_product_name }} no valor de R${{ modal_price_amount }}.
    </p>

    <p>
      Deseja confirmar a assinatura?
    </p>

    <template slot="action-btn">
      <SPEButtonWithFeedback
        btn_class="btn btn-success col-12 col-sm-4 text-white"
        btn_text="Assinar o plano"
        :request_pending="request_pending"
        :handle_click="subscribe"
      />
    </template>

    <template
      slot="dismiss-modal-text"
    >
      Não desejo assinar agora
    </template>
  </SPEModal>
</template>

<script>
  import SPEModal from '../../shared/SPEModal.vue';
  import SPEButtonWithFeedback from '../../shared/SPEButtonWithFeedback.vue';

  export default {
    name: 'ConfirmSubscribeModal',
    components: {
      SPEModal,
      SPEButtonWithFeedback,
    },

    props: {
      modal_product_name: {
        type: String,
        default: '',
      },

      modal_price_amount: {
        type: String,
        default: '',
      },

      subscribe_product: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        has_action_button: true,
        request_pending: false,
      };
    },

    methods: {
      showModal() {
        this.$refs.modal.showModal = true;
      },

      closeModal() {
        this.$refs.modal.showModal = false;
      },

      subscribe() {
        this.request_pending = true;
        this.$emit('subscribe', this.subscribe_product);
      },
    }
  };
</script>
