<template>
  <div
    ref="packagesTable"
    class="d-none d-md-block"
  >
    <hr class="mt-0" />

    <div class="row">
      <div class="col-12">
        <ul class="list-unstyled d-flex justify-content-start filter-options">
          <li
            class="mx-3"
            :class="getActiveFilter(packages_status.in_progress)"
            @click="fetchPackages(packages_status.in_progress)"
          >
            <span>
              {{ $t('packages.status.in_progress') }}
            </span>
          </li>

          <li
            class="mx-3"
            :class="getActiveFilter(packages_status.finished)"
            @click="fetchPackages(packages_status.finished)"
          >
            <span>
              {{ $t('packages.status.finished') }}
            </span>
          </li>

          <li
            class="mx-3"
            :class="getActiveFilter(packages_status.issued)"
            @click="fetchPackages(packages_status.issued)"
          >
            <span>
              {{ $t('packages.status.issued') }}
            </span>
          </li>

          <li
            class="mx-3"
            :class="getActiveFilter(packages_status.without_events)"
            @click="fetchPackages(packages_status.without_events)"
          >
            <span>
              {{ $t('packages.status.without_events') }}
            </span>
          </li>

          <li
            class="mx-3"
            :class="getActiveFilter(packages_status.no_filter)"
            @click="fetchPackages(packages_status.no_filter)"
          >
            <span>
              {{ $t('packages.status.all') }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <table class="table table-hover mb-0">
      <tbody>
        <template v-if="request_pending">
          <tr>
            <td
              scope="row"
              colspan="6"
            >
              <ClipLoader color="#f3b008" />
            </td>
          </tr>
        </template>

        <template v-else>
          <template v-if="user_packages.length">
            <tr
              v-for="user_package in user_packages"
              :key="user_package.id"
            >
              <td width="100%">
                <div class="row">
                  <div class="col-12 col-md-2">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.common.created_at') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ formatDate(user_package.created_at) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-4">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.latest_event') }}
                      </small>
                    </div>

                    <spe-table-data-container :data_text="getPackageLatestEvent(user_package)" />
                  </div>

                  <div class="col-12 col-md-2">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.latest_event_date') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ getPackageLatestEventHappenedAt(user_package) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-4 text-right">
                    <a
                      :href="setPackageUrl(user_package)"
                      class="btn btn-sm btn-info text-white"
                    >
                      {{ $t('actions.common.show') }}
                    </a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.tracking_code') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ user_package.tracking_code }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-2">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.shipping_company') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ capitalizeFirstLetter(user_package.shipping_company) }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.description') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ user_package.description }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-4">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.customer_email') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ getPackageEmail(user_package) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-2">
                    <div>
                      <small class="text-muted">
                        {{ $t('fields.packages.customer_phone') }}
                      </small>
                    </div>

                    <div>
                      <p class="mb-0">
                        {{ getPackagePhone(user_package) }}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <template v-else>
            <tr class="text-center">
              <td
                scope="row"
                colspan="6"
              >
                {{ getNoDataText }}
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  import capitalizeFirstLetter from '../../../helpers/capitalize-first-letter';
  import formatDate from '../../../helpers/format-date';
  import formatPhone from '../../../helpers/format-phone';

  import packages_status from '../../../static/packages_status';

  export default {
    name: 'PackagesTable',
    components: { ClipLoader },
    props: {
      user_packages: {
        type: Array,
        default: () => [],
      },

      request_pending: {
        type: Boolean,
        default: false,
      },

      uri_show_package: {
        type: String,
        required: true,
      },

      active_filter: {
        type: String,
        default: '',
      },

      api_error: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        packages_status,
      };
    },

    computed: {
      getNoDataText() {
        if (this.api_error) {
          return this.$t('packages.err');
        }

        if (this.active_filter) {
          switch (this.active_filter) {
          case this.packages_status.finished:
            return this.$t('packages.no_finished_packages');

          case this.packages_status.in_progress:
            return this.$t('packages.no_in_progress_packages');

          case this.packages_status.issued:
            return this.$t('packages.no_issued_packages');

          default:
            return this.$t('packages.no_packages');
          }
        }

        return this.$t('packages.no_packages_with_filter');
      },
    },

    methods: {
      capitalizeFirstLetter,
      formatDate,
      getPackageLatestEvent(user_package) {
        const { latest_event } = user_package;

        if (latest_event) {
          return latest_event.description_translated;
        }

        return this.$t('packages.no_latest_event');
      },

      getPackageLatestEventHappenedAt(user_package) {
        const { latest_event } = user_package;

        if (latest_event) {
          return this.formatDate(latest_event.happened_at, true);
        }

        return '-';
      },

      setPackageUrl(user_package) {
        return this.uri_show_package.replace(':package_id', user_package.id);
      },

      getPackageEmail(user_package) {
        return user_package.customer_email || '-';
      },

      getPackagePhone(user_package) {
        return formatPhone(user_package.customer_phone) || '-';
      },

      fetchPackages(filter) {
        return this.$emit('fetch-packages', filter);
      },

      getActiveFilter(filter) {
        if (filter === this.active_filter) {
          return 'active-filter';
        }

        return '';
      },
    },
  };
</script>

<style scoped>
  .filter-options li {
    cursor: pointer;
    padding-bottom: 5px;
  }

  .active-filter {
    font-weight: bold;
    border-bottom: 2px solid #f3b008;
  }
</style>
