<template>
  <div class="row">
    <h5 class="text-dark col-12 text-center mb-3">
      Redefina sua senha
    </h5>

    <form
      method="POST"
      :action="uri_update_password"
      class="col-12 col-sm-8 mx-auto"
      @submit="submitForm"
    >
      <input
        type="hidden"
        name="_token"
        :value="csrf"
      />

      <input
        type="hidden"
        name="token"
        :value="token"
      />

      <div class="mt-lg-5">
        <div class="form-group">
          <input
            id="email"
            v-model="email"
            type="email"
            class="form-control"
            name="email"
            autofocus
            placeholder="Endereço de e-mail"
            :class="checkInvalidField('email')"
            @blur="$v.email.$touch()"
            @input="clearFeedbackErrors('email')"
          />

          <div>
            <span
              v-if="!$v.email.required && $v.email.$error"
              class="text-danger font-weight-bold text-small"
            >
              O email é obrigatório
            </span>

            <span
              v-if="!$v.email.email && $v.email.$error"
              class="text-danger font-weight-bold text-small"
            >
              O email deve ter formato válido
            </span>
          </div>

          <div>
            <span
              v-if="errors_data.has('email') && (errors_data.get('email') !== token_error)"
              class="text-danger font-weight-bold text-small"
            >
              {{ errors_data.get('email') }}
            </span>
          </div>
        </div>

        <div class="form-group">
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control"
            name="password"
            placeholder="Senha"
            :class="checkInvalidField('password')"
            @blur="$v.password.$touch()"
            @input="clearFeedbackErrors('password')"
          />

          <div>
            <span
              v-if="!$v.password.minLength && $v.password.$error"
              class="text-danger font-weight-bold text-small"
            >
              A senha deve possuir no mínimo {{ min_password_length }} caracteres
            </span>

            <span
              v-if="!$v.password.required && $v.password.$error"
              class="text-danger font-weight-bold text-small"
            >
              A senha é obrigatória
            </span>
          </div>

          <div>
            <span
              v-if="errors_data.has('password')"
              class="text-danger font-weight-bold text-small"
            >
              {{ errors_data.get('password') }}
            </span>
          </div>
        </div>

        <div class="form-group">
          <input
            id="password-confirm"
            v-model="confirm_password"
            type="password"
            class="form-control"
            name="password_confirmation"
            placeholder="Confirmação de senha"
            :class="checkInvalidField('confirm_password')"
            @blur="$v.confirm_password.$touch()"
            @input="clearFeedbackErrors('confirm_password')"
          />

          <div>
            <span
              v-if="!$v.confirm_password.required && $v.confirm_password.$error"
              class="text-danger font-weight-bold text-small"
            >
              A senha deve ser confirmada
            </span>

            <span
              v-if="!$v.confirm_password.sameAsPassword && $v.confirm_password.$error"
              class="text-danger font-weight-bold text-small"
            >
              As duas senhas devem ser iguais
            </span>
          </div>

          <div>
            <span
              v-if="errors_data.has('password-confirmation')"
              class="text-danger font-weight-bold text-small"
            >
              {{ errors_data.get('password-confirmation') }}
            </span>
          </div>
        </div>

        <button
          id="reset-password-button"
          type="submit"
          class="btn btn-primary text-white btn-block my-3"
        >
          Alterar minha senha
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import { sameAs, email, required, minLength } from 'vuelidate/lib/validators';

  import Errors from '../../domain/Errors';

  export default {
    name: 'ResetPasswordForm',
    props: {
      uri_update_password: {
        type: String,
        required: true
      },

      backend_errors: {
        type: [ Array, Object ],
        required: true
      },

      fill: {
        type: [ Array, Object ],
        required: true
      },

      csrf: {
        type: String,
        required: true
      },

      token: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        errors_data: new Errors(this.backend_errors),
        email: '',
        password: '',
        confirm_password: '',
        token_error: 'Este token de redefinição de senha é inválido.',
        min_password_length: 8,
      };
    },

    validations() {
      return {
        email: {
          required,
          email,
        },
        password: {
          minLength: minLength(this.min_password_length),
          required,
        },
        confirm_password: {
          sameAsPassword: sameAs('password'),
          required,
        },
      };
    },

    mounted: function() {
      if (!Array.isArray(this.fill)) {
        this.email = this.fill.email;
      }
    },

    methods: {
      checkInvalidField(field) {
        if (this.errors_data.has(field) && !this.isTokenError(field) || this.$v[field].$error) {
          return 'is-invalid';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors_data.clear(field);
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },

      isTokenError(field) {
        if (this.errors_data.get(field) === this.token_error) {
          return true;
        }

        return false;
      },
    }
  };
</script>
