<template>
  <div class="dropdown dropdown-container">
    <button
      class="btn btn-primary btn-sm btn-block text-white dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ current_label }}
    </button>
    <div class="dropdown-menu">
      <span
        v-for="period in periods"
        :key="period.label"
        class="dropdown-item spe-dropdown-item"
        :class="checkIfLabelIsDisabled(period.label)"
        @click="changePeriod(period)"
      >
        {{ period.label }}
      </span>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  import periods from '../../../static/dashboard_chart_periods';

  export default {
    name: 'DropdownMenu',
    data() {
      return {
        current_label: null,
        periods,
      };
    },

    mounted() {
      const [default_period] = this.periods.filter(period => period.default);
      this.current_label = default_period.label;
    },

    methods: {
      checkIfLabelIsDisabled(label) {
        if (label === this.current_label) {
          return 'disabled';
        }
      },

      changePeriod(new_period) {
        const { period, label, interval } = new_period;
        this.current_label = label;
        const start_date = moment().subtract(interval, period).format('X');
        const end_date = moment().format('X');

        return this.$emit('changed-period', { start_date, end_date });
      },
    },
  };
</script>

<style scoped>
  .dorpdown-container {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .spe-dropdown-item { cursor: pointer; }
</style>
