<script>
  import { Line, mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  export default {
    name: 'LineChart',
    extends: Line,
    mixins: [reactiveProp],
    data() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 1,
            },
          },

          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },

              ticks: {
                maxTicksLimit: 8,
                maxRotation: 0,
              },
            }],

            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            }],
          },
        },
      };
    },

    mounted() {
      this.renderChart(this.chartData, this.options);
    },
  };
</script>
