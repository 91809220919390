<template>
  <transition name="fade">
    <div
      v-if="showModal"
      class="spe-modal"
    >
      <div class="spe-modal-dialog">
        <div class="spe-modal-header">
          <h1 class="modal-title h4 mx-auto">
            <slot name="header">
              {{ title }}
            </slot>
          </h1>

          <button
            type="button"
            class="close"
            @click="showModal=false"
          >
            <span aria-hidden="true">
              &times;
            </span>
          </button>
        </div>

        <div class="spe-modal-body">
          <slot>
            {{ content }}
          </slot>
        </div>

        <div>
          <slot name="footer" />

          <div class="row">
            <div
              class="col-12"
              :class="buttonsStyles"
            >
              <slot name="action-btn" />

              <button
                v-if="show_dismiss_button"
                type="button"
                class="btn btn-link btn-modal"
                @click="dismissModal"
              >
                <slot name="dismiss-modal-text">
                  {{ footer }}
                </slot>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="backdrop"
        @click="showModal=false"
      />
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'BaseModal',
    props: {
      show_dismiss_button: {
        type: Boolean,
        default: true,
      },

      has_action_button: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        title: '',
        content: '',
        footer: 'Cancelar',
        showModal: false
      };
    },

    computed: {
      buttonsStyles() {
        if (this.has_action_button) {
          return 'd-flex justify-content-between';
        }

        return 'text-right';
      }
    },

    created() {
      const onEscape = (e) => {
        if (this.showModal && e.keyCode === 27) {
          this.dismissModal();
        }
      };

      document.addEventListener('keydown', onEscape);

      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', onEscape);
      });
    },

    methods: {
      dismissModal() {
        this.showModal = false;
        this.$emit('callback');
      },
    },
  };
</script>

<style scoped>
  .spe-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1031;
    width: 100%;
    height: 100vh;
  }

  .spe-modal .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .2);
    z-index: 1;
  }

  .spe-modal-dialog {
    max-height: calc(100vh - 25px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    width: 100%;
    max-width: 560px;
    background: #fff;
    border-radius: 3px;
    padding: 30px;
    z-index: 2;
  }

  .spe-modal-header {
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .spe-modal-header .close {
      height: 46px;
      width: 46px;
      position: absolute;
      top: 0;
      right: 0;
    }

  .spe-modal-header .close:hover { background: #ced4da; }
  .spe-modal-body {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    min-height: 120px;
  }

  .btn-modal { color: #f3b008 !important; }
  .fade-enter-active, .fade-leave-active { transition: opacity .5s; }
  .fade-enter, .fade-leave-to { opacity: 0; }
</style>
