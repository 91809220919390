<template>
  <div class="card carriers-container">
    <div class="card-body">
      <h4 class="card-title">
        Pacotes por transportadora
      </h4>

      <small class="card-text description-chart">
        Aqui você pode visualizar como seus pacotes estão distribuídos pelas transportadoras que você utiliza.
      </small>

      <div
        v-if="request_pending"
        class="pt-5"
      >
        <ClipLoader color="#f3b008" />
      </div>

      <div
        v-else
        class="pt-5"
      >
        <div v-if="!renderChart">
          <hr />

          <p class="card-text">
            Não há dados para serem exibidos aqui
          </p>
        </div>

        <SPEPieChart
          v-if="chart_data.labels.length > 0"
          :chart-data="chart_data"
          :style="graphStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import _ from 'lodash';
  import moment from 'moment';

  import SPEPieChart from './charts/SPEPieChart.vue';

  import { fetchPackagesPerCarrierMetric } from '../../api';

  import colors from '../../helpers/colors';

  export default {
    name: 'PackagesPerCarrierCartContainer',
    components: {
      ClipLoader,
      SPEPieChart,
    },

    data() {
      return {
        request_pending: false,

        chart_data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderWidth: 1,
            },
          ],
        },
      };
    },

    computed: {
      renderChart() {
        return this.chart_data.labels.length > 0;
      },

      graphStyle() {
        return {
          position: 'relative',
          height: '375px'
        };
      },
    },

    async mounted() {
      await this.fetchChartData();
    },

    methods: {
      mountChart(data) {
        const chart_labels = _(data).map('label')
          .map(label => label.toUpperCase())
          .value();

        const chart_data = _.map(data, 'value');
        const chart_colors = _.map(chart_labels, (label, index) => {
          const color_index = index % colors.length;

          return colors[color_index];
        });

        this.chart_data.labels = chart_labels;
        this.chart_data.datasets[0].data = chart_data;
        this.chart_data.datasets[0].backgroundColor = chart_colors;

      },

      async fetchChartData(period) {
        try {
          this.request_pending = true;
          const { data: { data } } = await fetchPackagesPerCarrierMetric(period);

          return this.mountChart(data);
        } catch (err) {
          const { response: { status } = {} } = err;

          if (status === 401) {
            this.$emit('show-modal');
          }
        } finally {
          this.request_pending = false;
        }
      }
    },
  };
</script>

<style scoped>
  .carriers-container {
    grid-row: 1/5;
    grid-column: 2/3;
  }
</style>
