class Errors {
  constructor(errors) {
    this.errors = errors || {};
  }

  has(field) {
    /*eslint-disable-next-line no-prototype-builtins */
    return this.errors.hasOwnProperty(field);
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  clear(field) {
    return delete this.errors[field];
  }
}

export default Errors;
