<template>
  <div class="card packages-status-container">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('packages.on_way') }}
      </h4>

      <small class="card-text">
        {{ $t('packages.refused_and_progress') }}
      </small>

      <div class="data-container pt-1">
        <div>
          <div v-if="request_pending">
            <ClipLoader color="#f3b008" />
          </div>

          <div v-else>
            <span class="data-content">
              {{ packages_in_progress }}
            </span> {{ $t('packages.in_progress') }}.
          </div>
        </div>

        <div>
          <div v-if="request_pending">
            <ClipLoader color="#f3b008" />
          </div>

          <div v-else>
            <span class="data-content">
              {{ refused_packages }}
            </span> {{ $t('packages.refused') }}.
          </div>
        </div>

        <div>
          <div v-if="request_pending">
            <ClipLoader color="#f3b008" />
          </div>

          <div v-else>
            <span class="data-content">
              {{ without_events_packages }}
            </span> {{ $t('packages.without_events') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  import {
    fetchPackagesInProgressMetric,
    fetchRefusedPackagesMetric,
    fetchPackagesWithoutEventsMetric,
  } from '../../api';

  export default {
    name: 'PackagesStatusContainer',
    components: { ClipLoader },
    data() {
      return {
        request_pending: false,
        packages_in_progress: null,
        refused_packages: null,
        without_events_packages: null,
        average_time: null,
      };
    },

    async mounted() {
      await this.fetchChartData();
    },

    methods: {
      mountChart(packages_in_progress, refused_packages, without_events_packages) {
        this.packages_in_progress = packages_in_progress[0].value;
        this.refused_packages = refused_packages[0].value;
        this.without_events_packages = without_events_packages[0].value;
      },

      async fetchChartData(period) {
        try {
          this.request_pending = true;
          const [
            {
              data: {
                data: packages_in_progress,
              },
            },

            {
              data: {
                data: refused_packages,
              },
            },

            {
              data: {
                data: without_events_packages,
              },
            },
          ] = await Promise.all([
            fetchPackagesInProgressMetric(period),
            fetchRefusedPackagesMetric(period),
            fetchPackagesWithoutEventsMetric(period),
          ]);

          return this.mountChart(packages_in_progress, refused_packages, without_events_packages);
        } catch (err) {
          const { response: { status } = {} } = err;

          if (status === 401) {
            this.$emit('show-modal');
          }
        } finally {
          this.request_pending = false;
        }
      },
    },
  };
</script>

<style scoped>
  .packages-status-container{
    grid-row: 4/6;
    grid-column: 1/2;
  }

  .data-content {
    font-weight: bold;
    font-size: 3rem;
  }

  .data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
</style>
