import moment from 'moment';

function formatPhone(date, include_time = false) {
  if (moment(date).isValid) {
    return include_time ? moment(date).format('DD/MM/YYYY  HH:mm') : moment(date).format('DD/MM/YYYY');
  }

  return '';
}

export default formatPhone;
