<template>
  <button
    :class="btn_class"
    :disabled="request_pending || disabled"
    :type="btn_type"
    @click="handleClick"
  >
    <template v-if="!request_pending">
      {{ btn_text }}
    </template>

    <template v-else>
      <clip-loader
        :color="btn_color"
        :size="btn_size"
      />
    </template>
  </button>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  export default {
    name: 'ButtonWithFeedback',
    components: { ClipLoader },
    props: {
      btn_text: {
        required: true,
        type: String,
      },

      btn_class: {
        required: true,
        type: [ String, Function ],
      },

      btn_color: {
        type: String,
        default: '#ffffff',
      },

      btn_size: {
        type: String,
        default: '18px',
      },

      btn_type: {
        type: String,
        default: 'button',
      },

      request_pending: {
        type: Boolean,
        required: true,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      handle_click: {
        type: Function,
        default: () => {},
      },
    },

    methods: {
      handleClick: function() {
        this.handle_click();
      }
    }
  };
</script>
