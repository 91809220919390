<template>
  <div class="container">
    <SPECreatePackageForm
      :backend_errors="backend_errors"
      :csrf_token="csrf_token"
      :fill="fill"
      :manual_url="manual_url"
      :shipping_companies="shipping_companies"
      :uri_create_package="uri_create_package"
    />

    <div class="card my-3">
      <div class="card-body">
        <h3 class="card-title">
          {{ $t('packages.packages') }}
        </h3>

        <p class="card-text">
          {{ $t('packages.all_packages') }}
        </p>

        <hr />

        <p class="card-text text-muted">
          {{ $t('packages.search_packages') }}
        </p>

        <SPEPackagesSearch
          :available_events="available_events"
          :shipping_companies="shipping_companies"
          @fetch-filtered-packages="fetchPackages"
        />

        <SPEPackagesList
          :active_filter="active_filter"
          :api_error="api_error"
          :request_pending="request_pending"
          :uri_show_package="uri_show_package"
          :user_packages="getPackagesData"
          @fetch-packages="fetchPackages"
        />
      </div>

      <SPEPackagesTable
        :active_filter="active_filter"
        :api_error="api_error"
        :request_pending="request_pending"
        :uri_show_package="uri_show_package"
        :user_packages="getPackagesData"
        @fetch-packages="fetchPackages"
      />

      <div
        v-if="paginationShouldRender"
        class="card-body text-center"
      >
        <div class="btn-group">
          <button
            class="btn"
            :class=" paginationHasPreviousLink ? 'btn-outline-primary' : 'btn-outline-warning text-warning'"
            :disabled="!paginationHasPreviousLink"
            @click="fetchPreviousPage"
          >
            {{ $t('actions.pagination.prev') }}
          </button>

          <button
            class="btn"
            :class="paginationHasNextLink ? 'btn-outline-primary' : 'btn-outline-warning text-warning'"
            :disabled="!paginationHasNextLink"
            @click="fetchNextPage"
          >
            {{ $t('actions.pagination.next') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SPEPackagesSearch from './partials/SPEPackagesSearch.vue';
  import SPECreatePackageForm from './partials/SPECreatePackageForm.vue';
  import SPEPackagesList from './partials/SPEPackagesList.vue';
  import SPEPackagesTable from './partials/SPEPackagesTable.vue';

  import { fetchEvents, fetchPackages } from '../../api';

  import packages_status from '../../static/packages_status';

  export default {
    name: 'PackagesIndex',
    components: {
      SPECreatePackageForm,
      SPEPackagesList,
      SPEPackagesSearch,
      SPEPackagesTable,
    },

    props: {
      backend_errors: {
        type: [ Array, Object ],
        required: true,
      },

      csrf_token: {
        type: String,
        required: true,
      },

      fill: {
        type: [ Array, Object ],
        required: true,
      },

      manual_url: {
        type: String,
        required: true,
      },

      shipping_companies: {
        type: Array,
        required: true,
      },

      uri_create_package: {
        type: String,
        required: true,
      },

      uri_show_package: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        active_filter: packages_status.in_progress,
        api_error: false,
        available_events: [],
        page: 1,
        request_pending: false,
        user_packages: {},
        shipping_company: '',
        customer_name: '',
        description: '',
        latest_event: '',
        tracking_code: '',
      };
    },

    computed: {
      paginationHasPreviousLink() {
        const { links: { prev = '' } = {} } = this.user_packages || {};

        return !!prev;
      },

      paginationHasNextLink() {
        const { links: { next = '' } = {} } = this.user_packages || {};

        return !!next;
      },

      paginationShouldRender() {
        return (this.paginationHasPreviousLink || this.paginationHasNextLink) && !this.request_pending;
      },

      getPackagesData() {
        const { data = [] } = this.user_packages;

        return data;
      }
    },

    mounted() {
      this.fetchPackages(packages_status.in_progress);
      this.fetAvailableEvents();
    },

    methods: {
      async fetAvailableEvents() {
        const { data } = await fetchEvents();
        this.available_events = data.map(event => event.description_translated);
      },

      async fetchPackages(filter) {
        const {
          shipping_company = '',
          customer_name = '',
          description = '',
          latest_event = '',
          tracking_code = ''
        } = filter;

        this.shipping_company = shipping_company;
        this.customer_name = customer_name;
        this.description = description;
        this.latest_event = latest_event;
        this.tracking_code = tracking_code;
        this.api_error = false;
        this.request_pending = true;
        this.page = 1;
        this.active_filter = typeof filter === 'object' ? '' : filter;

        try {
          this.user_packages = await fetchPackages({
            package_status: this.active_filter,
            page: this.page,
            ...filter,
          });
        } catch (err) {
          this.api_error = !!err;
        } finally {
          this.request_pending = false;
        }
      },

      async fetchPreviousPage() {
        this.api_error = false;
        this.request_pending = true;
        this.page--;

        try {
          this.user_packages = await fetchPackages({
            package_status: this.active_filter,
            shipping_company: this.shipping_company,
            customer_name: this.customer_name,
            description: this.description,
            latest_event: this.latest_event,
            tracking_code: this.tracking_code,
            page: this.page,
          });
        } catch (err) {
          this.api_error = !!err;
        } finally {
          this.request_pending = false;
        }
      },

      async fetchNextPage() {
        this.api_error = false;
        this.request_pending = true;
        this.page++;

        try {
          this.user_packages = await fetchPackages({
            package_status: this.active_filter,
            shipping_company: this.shipping_company,
            customer_name: this.customer_name,
            description: this.description,
            latest_event: this.latest_event,
            tracking_code: this.tracking_code,
            page: this.page,
          });
        } catch (err) {
          this.api_error = !!err;
        } finally {
          this.request_pending = false;
        }
      },
    },
  };
</script>
