<template>
  <div
    ref="packagesList"
    class="d-block d-md-none"
  >
    <div class="row mt-3">
      <div class="col-12">
        <ul class="list-unstyled d-flex justify-content-between filter-options">
          <div class="row">
            <li class="col-6">
              <button
                :class="getActiveFilter(packages_status.in_progress)"
                class="btn btn-sm btn-block my-1"
                @click="fetchPackages(packages_status.in_progress)"
              >
                {{ $t('packages.status.in_progress') }}
              </button>
            </li>

            <li class="col-6">
              <button
                :class="getActiveFilter(packages_status.finished)"
                class="btn btn-sm btn-block my-1"
                @click="fetchPackages(packages_status.finished)"
              >
                {{ $t('packages.status.finished') }}
              </button>
            </li>

            <li class="col-6">
              <button
                :class="getActiveFilter(packages_status.issued)"
                class="btn btn-sm btn-block my-1"
                @click="fetchPackages(packages_status.issued)"
              >
                {{ $t('packages.status.issued') }}
              </button>
            </li>

            <li class="col-6">
              <button
                :class="getActiveFilter(packages_status.no_filter)"
                class="btn btn-sm btn-block my-1"
                @click="fetchPackages(packages_status.no_filter)"
              >
                {{ $t('packages.status.all') }}
              </button>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <template v-if="request_pending">
      <div class="mb-3">
        <ClipLoader color="#f3b008" />
      </div>
    </template>

    <template v-else>
      <template v-if="user_packages.length">
        <template v-for="user_package in user_packages">
          <div :key="user_package.id">
            <hr />

            <ul class="list-unstyled">
              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.tracking_code') }}:
                </small>

                {{ user_package.tracking_code }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.description') }}:
                </small>

                {{ user_package.description }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.latest_event') }}:
                </small>

                {{ getPackageLatestEvent(user_package) }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.customer_phone') }}:
                </small>

                {{ getPackagePhone(user_package) }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.customer_email') }}:
                </small>

                {{ getPackageEmail(user_package) }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.common.created_at') }}:
                </small>

                {{ formatDate(user_package.created_at) }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.latest_event_date') }}:
                </small>

                {{ getPackageLatestEventHappenedAt(user_package) }}
              </li>

              <li class="py-1">
                <small class="text-muted">
                  {{ $t('fields.packages.shipping_company') }}:
                </small>

                {{ capitalizeFirstLetter(user_package.shipping_company) }}
              </li>

              <li class="py-1">
                <a
                  :href="getPackageUrl(user_package)"
                  class="btn btn-block btn-info text-white"
                >
                  {{ $t('actions.common.show') }}
                </a>
              </li>
            </ul>
          </div>
        </template>
      </template>

      <template v-else>
        <hr />

        <ul class="text-center row list-unstyled mx-auto">
          <li class="py-1">
            <span>
              {{ getNoDataText }}
            </span>
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>

<script>
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  import capitalizeFirstLetter from '../../../helpers/capitalize-first-letter';
  import formatDate from '../../../helpers/format-date';
  import formatPhone from '../../../helpers/format-phone';

  import packages_status from '../../../static/packages_status';

  export default {
    name: 'PackagesList',
    components: { ClipLoader },
    props: {
      user_packages: {
        type: Array,
        default: () => [],
      },

      request_pending: {
        type: Boolean,
        default: false,
      },

      uri_show_package: {
        type: String,
        required: true,
      },

      active_filter: {
        type: String,
        default: '',
      },

      api_error: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        packages_status,
      };
    },

    computed: {
      getNoDataText() {
        if (this.api_error) {
          return this.$t('packages.err');
        }

        if (this.active_filter) {
          switch (this.active_filter) {
          case this.packages_status.finished:
            return this.$t('packages.no_finished_packages');

          case this.packages_status.in_progress:
            return this.$t('packages.no_in_progress_packages');

          case this.packages_status.issued:
            return this.$t('packages.no_issued_packages');

          default:
            return this.$t('packages.no_packages');
          }
        }

        return this.$t('packages.no_packages_with_filter');
      },
    },

    methods: {
      capitalizeFirstLetter,
      formatDate,
      getPackageLatestEvent(user_package) {
        const { latest_event } = user_package;

        if (latest_event) {
          return latest_event.description_translated;
        }

        return this.$t('packages.no_latest_event');
      },

      getPackageLatestEventHappenedAt(user_package) {
        const { latest_event } = user_package;

        if (latest_event) {
          return this.formatDate(latest_event.happened_at, true);
        }

        return '-';
      },

      getPackageUrl(user_package) {
        return this.uri_show_package.replace(':package_id', user_package.id);
      },

      getPackageEmail(user_package) {
        return user_package.customer_email || '-';
      },

      getPackagePhone(user_package) {
        return formatPhone(user_package.customer_phone) || '-';
      },

      fetchPackages(filter) {
        return this.$emit('fetch-packages', filter);
      },

      getActiveFilter(filter) {
        if (filter === this.active_filter) {
          return 'btn-primary text-white';
        }

        return 'btn-outline-primary';
      },
    },
  };
</script>
