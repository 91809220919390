<template>
  <div class="row">
    <div class="col-12 col-sm-6 mx-auto">
      <SPEEnvAlert />
    </div>

    <form
      method="POST"
      :action="uri_login"
      class="col-12 mx-auto"
      @submit="submitForm"
    >
      <input
        type="hidden"
        name="_token"
        :value="csrf_token"
      />

      <div class="row">
        <div class="col-12 col-sm-6 mx-auto">
          <div class="my-lg-5">
            <div
              v-if="session_success"
              class="alert alert-success text-center"
            >
              {{ session_success }}
            </div>

            <div class="form-group">
              <input
                id="email"
                v-model="email"
                placeholder="E-mail"
                class="form-control"
                name="email"
                :class="inputHasErrors('email')"
                @blur="$v.email.$touch()"
                @input="clearFeedbackErrors('email')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.email.required && $v.email.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O email é obrigatório
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.email.email && $v.email.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O email deve ter um formato válido
                  </span>
                </div>

                <span
                  v-if="errors.has('email')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('email') }}
                </span>
              </div>
            </div>

            <div class="form-group">
              <input
                id="password"
                v-model="password"
                type="password"
                placeholder="Senha"
                class="form-control"
                name="password"
                :class="inputHasErrors('password')"
                @blur="$v.password.$touch()"
                @input="clearFeedbackErrors('password')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.password.required && $v.password.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha é obrigatória
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.password.minLength && $v.password.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha deve possuir no mínimo {{ min_password_length }} caracteres
                  </span>
                </div>

                <span
                  v-if="errors.has('password')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('paswword') }}
                </span>
              </div>
            </div>

            <div class="form-check text-left">
              <input
                id="remember"
                v-model="remember"
                class="form-check-input"
                type="checkbox"
                name="remember"
              />

              <label
                class="form-check-label"
                for="remember"
              >
                Lembrar
              </label>
            </div>

            <button
              class="btn btn-primary text-white btn-block my-3"
              type="submit"
            >
              {{ $t('actions.common.enter') }}
            </button>

            <div class="text-center">
              <a :href="uri_recover_password">
                Esqueci minha senha
              </a>

              <span>|</span>

              <a :href="uri_register">
                Quero me cadastrar
              </a>
            </div>

            <div class="mt-3 text-center">
              Vejas nossos

              <a
                :href="uri_terms_of_use"
                target="_blank"
              >
                termos de uso
              </a>

              e nossa

              <a
                :href="uri_privacy_policy"
                target="_blank"
              >
                política de privacidade
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { required, minLength, email } from 'vuelidate/lib/validators';

  import SPEEnvAlert from './partials/SPEEnvAlert.vue';

  import Errors from '../../domain/Errors';

  export default {
    name: 'LoginForm',
    components: { SPEEnvAlert },
    props: {
      backend_errors: {
        required: true,
        type: [Array, Object],
      },

      csrf_token: {
        required: true,
        type: String,
      },

      fill: {
        required: true,
        type: [Array, Object],
      },

      uri_recover_password: {
        required: true,
        type: String,
      },

      uri_register: {
        required: true,
        type: String,
      },

      uri_login: {
        required: true,
        type: String,
      },

      session_success: {
        required: true,
        type: String,
      },

      uri_terms_of_use: {
        required: true,
        type: String,
      },

      uri_privacy_policy: {
        required: true,
        type: String,
      },
    },

    data() {
      return {
        errors: new Errors(this.backend_errors),
        email: '',
        password: '',
        remember: false,
        min_password_length: 8,
      };
    },

    validations() {
      return {
        email: {
          required,
          email,
        },

        password: {
          minLength: minLength(this.min_password_length),
          required,
        },
      };
    },

    mounted() {
      if (!Array.isArray(this.fill)) {
        this.email = this.fill.email;
      }
    },

    methods: {
      inputHasErrors(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'is-invalid';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors.clear(field);
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },
    }
  };
</script>
