import Vue from 'vue';
import $ from 'jquery';
import Popper from 'popper.js';

try {
  window.Vue = Vue;
  window.Popper = Popper;
  window.$ = window.jQuery = $;
  // eslint-disable-next-line no-unused-vars
} catch (e) {
  //
}
