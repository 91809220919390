<template>
  <div />
</template>

<script>
  import * as Sentry from '@sentry/browser';

  export default {
    props: {
      user_id: {
        required: true,
        type: String
      },

      user_email: {
        required: true,
        type: String
      },
    },

    created() {
      const id = this.user_id;
      const email = this.user_email;

      Sentry.configureScope(function(scope) {
        scope.setUser({
          id,
          email,
        });
      });
    },
  };
</script>
