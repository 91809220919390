<template>
  <SPEModal
    ref="modal"
    :has_action_button="has_action_button"
    @callback="closeModal"
  >
    <template slot="header">
      Cancelar notificações
    </template>

    <p>
      Você realmente deseja cancelar as notificações de rastreamento de pacote no seu email?
    </p>

    <p>
      Caso confirme não poderemos mais te informar sobre o andamento de seus pacotes.
    </p>

    <template slot="action-btn">
      <form
        :action="uri_action"
        method="POST"
      >
        <input
          type="hidden"
          name="_token"
          :value="csrf_token"
        />

        <input
          id="hash"
          type="hidden"
          :value="hash"
          name="hash"
        />

        <button
          type="submit"
          class="btn btn-danger text-white"
        >
          Cancelar notificações
        </button>
      </form>
    </template>

    <div slot="dismiss-modal-text">
      Continuar recebendo notificaões
    </div>
  </SPEModal>
</template>

<script>
  import SPEModal from '../shared/SPEModal.vue';

  export default {
    name: 'OptOutConfirmModal',
    components: { SPEModal },
    props: {
      uri_action: {
        type: String,
        required: true,
      },

      hash: {
        type: String,
        required: true,
      },

      csrf_token: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        has_action_button: true,
      };
    },

    methods: {
      showModal() {
        this.$refs.modal.showModal = true;
      },

      closeModal() {
        this.$refs.modal.showModal = false;
      },
    }
  };
</script>
