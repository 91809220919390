<template>
  <div class="row">
    <div class="col-12 col-sm-6 mx-auto">
      <SPEEnvAlert />
    </div>

    <form
      class="col-12 mx-auto"
      method="POST"
      :action="uri_register"
      @submit="submitForm"
    >
      <input
        type="hidden"
        :value="csrf_token"
        name="_token"
      />

      <div class="row">
        <div class="col-12 col-md-6 mx-auto">
          <div class="row">
            <div class="col-12 my-1">
              <label
                for="name"
                class="text-left"
              >
                Nome
              </label>

              <input
                id="name"
                v-model="name"
                type="text"
                class="form-control"
                :class="inputHasErrors('name')"
                name="name"
                autocomplete="name"
                autofocus
                @blur="$v.name.$touch()"
                @input="clearFeedbackErrors('name')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.name.required && $v.name.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O nome é obrigatório
                  </span>
                </div>

                <span
                  v-if="errors.has('name')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('name') }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <transition name="fade">
                <div
                  v-if="show_email_hint"
                  class="hint"
                >
                  <span>
                    Evite utilizar um e-mail de suporte. Além de utilizá-lo para login em nossa plataforma, poderemos entrar em contato com você através dele.
                  </span>
                </div>
              </transition>
              <label
                for="email"
                class="text-left"
              >
                Email
              </label>

              <input
                id="email"
                v-model="email"
                type="text"
                class="form-control"
                :class="inputHasErrors('email')"
                name="email"
                autocomplete="email"
                @blur="handleEmailInputOnBlur"
                @input="clearFeedbackErrors('email')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.email.required && $v.email.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O email é obrigatório
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.email.email && $v.email.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O email deve ter formato válido
                  </span>
                </div>

                <span
                  v-if="errors.has('email')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('email') }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <label
                for="phone"
                class="text-left"
              >
                Telefone
              </label>

              <input
                id="phone"
                v-model="phone"
                v-mask="['(##) #####-####', '(##) ####-####']"
                type="text"
                class="form-control"
                :class="inputHasErrors('phone')"
                name="phone"
                autocomplete="phone"
                @blur="$v.phone.$touch()"
                @input="clearFeedbackErrors('phone')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.phone.required && $v.phone.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O telefone é obrigatório
                  </span>
                </div>

                <span
                  v-if="errors.has('phone')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('phone') }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <label
                for="cpfcnpj"
                class="text-left"
              >
                CPF/CNPJ
              </label>

              <input
                id="cpfcnpj"
                v-model="cpfcnpj"
                v-mask="['##.###.###/####-##', '###.###.###-##']"
                type="cpfcnpj"
                class="form-control"
                :class="inputHasErrors('cpfcnpj')"
                name="cpfcnpj"
                autocomplete="cpfcnpj"
                @blur="$v.cpfcnpj.$touch()"
                @input="clearFeedbackErrors('cpfcnpj')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.cpfcnpj.required && $v.cpfcnpj.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O CPF/CNPJ é obrigatório
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.cpfcnpj.isValid && $v.cpfcnpj.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O CPF/CNPJ não é válido
                  </span>
                </div>

                <span
                  v-if="errors.has('cpfcnpj')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('cpfcnpj') }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <label
                for="password"
                class="text-left"
              >
                Senha
              </label>

              <input
                id="password"
                v-model="password"
                type="password"
                class="form-control"
                :class="inputHasErrors('password')"
                name="password"
                @blur="$v.password.$touch()"
                @input="clearFeedbackErrors('password')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.password.required && $v.password.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha é obrigatória
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.password.minLength && $v.password.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha deve possuir no mínimo {{ min_password_length }} caracteres
                  </span>
                </div>

                <span
                  v-if="errors.has('password')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('password') }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <label
                for="password_confirmation"
                class="text-left"
              >
                Confirmação de senha
              </label>

              <input
                id="password_confirmation"
                v-model="password_confirmation"
                type="password"
                class="form-control"
                :class="inputHasErrors('password_confirmation')"
                name="password_confirmation"
                @blur="$v.password_confirmation.$touch()"
                @input="clearFeedbackErrors('password_confirmation')"
              />

              <div>
                <div>
                  <span
                    v-if="!$v.password_confirmation.required && $v.password_confirmation.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    A senha deve ser confirmada
                  </span>
                </div>

                <div>
                  <span
                    v-if="!$v.password_confirmation.sameAsPassword && $v.password_confirmation.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    As duas senhas devem ser iguais
                  </span>
                </div>

                <span
                  v-if="errors.has('password_confirmation')"
                  class="text-danger font-weight-bold text-small"
                >
                  {{ errors.get('password_confirmation') }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <div class="form-check row">
                <div class="col-12 col-xl-10 mx-xl-auto d-block mx-auto text-center my-4">
                  <div class="custom-control custom-checkbox">
                    <input
                      id="termsOfService"
                      v-model="terms_of_use_checked"
                      type="checkbox"
                      class="custom-control-input"
                      :class="inputHasErrors('terms_of_use_checked')"
                      @change="$v.terms_of_use_checked.$touch()"
                    />

                    <label
                      class="custom-control-label"
                      for="termsOfService"
                    >
                      Li e estou de acordo com os
                      <a
                        :href="uri_terms_of_use"
                        target="_blank"
                      >
                        termos de uso
                      </a>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 my-1">
              <button
                type="submit"
                class="btn btn-primary btn-block text-white"
                @submit="submitForm"
              >
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { required, minLength, email, sameAs, } from 'vuelidate/lib/validators';
  import { isValid as isValidCnpj } from '@fnando/cnpj';
  import { isValid as isValidCpf } from '@fnando/cpf';

  import SPEEnvAlert from './partials/SPEEnvAlert.vue';

  import Errors from '../../domain/Errors';
  import { eventName, eventType } from '../../enum/events';
  import env from '../../enum/env';

  export default {
    name: 'RegisterForm',
    components:{ SPEEnvAlert },
    props: {
      csrf_token: {
        required: true,
        type: String,
      },

      uri_register: {
        required: true,
        type: String,
      },

      backend_errors: {
        type: [ Array, Object ],
        default: null,
      },

      fill: {
        type: [ Array, Object ],
        required: true,
      },

      uri_terms_of_use: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        eventName,
        eventType,
        env,
        name: '',
        email: '',
        cpfcnpj: '',
        phone: '',
        password: '',
        password_confirmation: '',
        terms_of_use_checked: false,
        errors: new Errors(this.backend_errors),
        request_pending: false,
        show_email_hint: false,
        min_password_length: 8,
      };
    },

    created: function() {
      if (!Array.isArray(this.fill)) {
        this.name = this.fill.name;
        this.email = this.fill.email;
        this.phone = this.fill.phone;
        this.cpfcnpj = this.fill.cpfcnpj;
      }
    },

    validations() {
      return {
        name: {
          required,
        },

        email: {
          required,
          email,
        },

        phone: {
          required,
        },

        cpfcnpj: {
          required,
          isValid: function(cpfcnpj) {
            return isValidCpf(cpfcnpj) || isValidCnpj(cpfcnpj);
          },
        },

        password: {
          minLength: minLength(this.min_password_length),
          required,
        },

        password_confirmation: {
          sameAsPassword: sameAs(function() {
            return this.password;
          }),
          required,
        },

        terms_of_use_checked: {
          sameAs: sameAs(() => true),
        },
      };
    },

    methods: {
      inputHasErrors(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'is-invalid';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors.clear(field);
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          if (window.location.origin === this.env.node_env.production) {
            fbq(this.eventType.custom, this.eventName.signup, {
              email: this.email,
              name: this.name,
              phone: this.phone,
            });
          }

          return true;
        }

        e.preventDefault();
      },

      handleEmailInputOnBlur() {
        const regex = /ajuda|contato|suporte/g;
        this.show_email_hint = regex.test(this.email);
        this.$v.email.$touch();
      }
    }
  };
</script>

<style scoped>
  .hint {
    background: #f3b008;
    border-radius: 4px;
    bottom: calc(80% + 12px);
    color: rgba(255, 255, 255, 1);
    left: 50%;
    padding: 8px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;
  }

  .hint::before {
    content: '';
    border-style: solid;
    border-color: #f3b008 transparent;
    border-width: 6px 6px 0 6px;
    top: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
