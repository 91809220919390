<template>
  <div
    v-if="alertShouldRender()"
    class="alert alert-warning"
    role="alert"
  >
    Atenção, este é um ambiente de testes, para acessar o SaiuPraEntrega <a :href="env.url.production">clique aqui.</a>
  </div>
</template>

<script>
  import env from '../../../enum/env';

  export default {
    name: 'EnvAlert',
    data() {
      return {
        env,
      };
    },

    methods: {
      alertShouldRender() {
        return this.env.url.staging === window.location.origin;
      },
    }
  };
</script>
