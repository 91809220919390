<template>
  <div>
    <JsonViewer
      :value="json_data"
      :copyable="copy_props"
      expand-depth="true"
      boxed="true"
    />
  </div>
</template>

<script>
  import JsonViewer from 'vue-json-viewer';

  export default {
    name: 'PrettyJson',
    components: { JsonViewer },
    props: {
      json_data: {
        type: Object,
        required: true,
      }
    },

    data() {
      return {
        copy_props: {
          copyText: 'copiar',
          copiedText: 'copiado',
          timeout: 1000,
        }
      };
    },
  };
</script>
