<template>
  <div class="row">
    <h5 class="text-dark col-12 text-center mb-3">
      Recuperar senha
    </h5>

    <form
      method="POST"
      :action="uri_password_recovery"
      class="col-12 col-sm-8 mx-auto"
      @submit="submitForm"
    >
      <input
        type="hidden"
        name="_token"
        :value="csrf_token"
      />

      <div class="my-lg-5">
        <div
          v-if="session_status"
          class="alert alert-success text-center"
        >
          {{ session_status }}
        </div>

        <div class="form-group">
          <input
            id="email"
            v-model="email"
            type="text"
            class="form-control"
            name="email"
            placeholder="Endereço de e-mail"
            :class="inputHasErrors('email')"
            @blur="$v.email.$touch()"
            @input="clearFeedbackErrors('email')"
          />

          <div>
            <span
              v-if="!$v.email.required && $v.email.$error"
              class="text-danger font-weight-bold text-small"
            >
              O email é obrigatório
            </span>
          </div>

          <div>
            <span
              v-if="!$v.email.email && $v.email.$error"
              class="text-danger font-weight-bold text-small"
            >
              O email deve ter formato válido
            </span>
          </div>

          <div>
            <span
              v-if="errors.has('email')"
              class="text-danger font-weight-bold text-small"
            >
              {{ errors.get('email') }}
            </span>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary text-white btn-block my-3"
        >
          Recuperar senha
        </button>

        <div class="text-center">
          <a :href="uri_login">
            Já tenho uma conta
          </a>

          <span>|</span>

          <a :href="uri_register">
            Quero me cadastrar
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators';

  import Errors from '../../domain/Errors';

  export default {
    name: 'RecoverForm',
    props: {
      csrf_token: {
        type: String,
        required: true,
      },

      uri_password_recovery: {
        type: String,
        required: true,
      },

      uri_login: {
        type: String,
        required: true,
      },

      uri_register: {
        type: String,
        required: true,
      },

      backend_errors: {
        type: [ Array, Object ],
        required: true,
      },

      fill: {
        type: [ Array, Object ],
        required: true,
      },

      session_status: {
        required: true,
        type: String,
      }
    },

    data() {
      return {
        errors: new Errors(this.backend_errors),
        email: ''
      };
    },

    validations: {
      email: {
        required,
        email
      }
    },

    mounted: function() {
      if (!Array.isArray(this.fill)) {
        this.email = this.fill.email;
      }
    },

    methods: {
      inputHasErrors(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'is-invalid';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors.clear(field);
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },
    },
  };
</script>
