export default {
  node_env: {
    development: 'development',
    production: 'production',
    staging: 'staging'
  },

  url: {
    production: 'https://app.saiupraentrega.com.br',
    staging: 'https://app.saiupraentrega.xyz',
    development: 'https://app.spe.localhost'
  },
};
