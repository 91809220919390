export default [
  {
    label: 'Últimos 7 dias',
    interval: 7,
    period: 'days',
  },

  {
    label: 'Últimos 15 dias',
    interval: 15,
    period: 'days',
  },

  {
    label: 'Último mês',
    interval: 30,
    period: 'days',
    default: true,
  },
];
