const eventType = {
  custom: 'trackCustom',
};

const eventName = {
  signup: 'Signup',
};

export {
  eventName,
  eventType,
};
