import parsePhoneNumber from 'libphonenumber-js';

function formatPhone(phone) {
  if (phone) {
    const phone_number = parsePhoneNumber(phone);

    if (phone_number) {
      return phone_number.formatNational();
    }
  }

  return phone;
}

export default formatPhone;
