$(document).ready(function () {
  if (!document.querySelector('#card-element')) {
    return false;
  }

  let stripe = Stripe($('meta[name="stripe"]').attr('content'));
  let elements = stripe.elements();
  let cardElement = elements.create('card', {
    'hidePostalCode': true,
  });

  cardElement.mount('#card-element');

  const cardButton = document.getElementById('card-button');
  const clientSecret = cardButton.dataset.secret;

  cardButton.addEventListener('click', async () => {
    const { setupIntent, error } = await stripe.confirmCardSetup(
      clientSecret, {
        payment_method: {
          card: cardElement
        }
      }
    );

    if (error) {
      document.getElementById('card-error').classList.add('d-block');
    } else {
      let stripeInputToken = document.getElementById('stripe_token');
      stripeInputToken.value = setupIntent.payment_method;

      document.getElementById('payment-method-form').submit();
    }
  });
});
