<script>
  import { Bar, mixins } from 'vue-chartjs';

  const { reactiveProp } = mixins;

  export default {
    name: 'BarChart',
    extends: Bar,
    mixins: [reactiveProp],
    data() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,

          layout: {
            padding: -5,
          },

          legend: {
            display: false,
          },

          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              }
            }],

            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                maxTicksLimit: 5,
              }
            }]
          }
        }
      };
    },

    mounted() {
      this.renderChart(this.chartData, this.options);
    },
  };
</script>
