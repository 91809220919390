<template>
  <SPEModal
    ref="modal"
    :has_action_button="has_action_button"
    @callback="closeModal"
  >
    <template slot="header">
      Sessão expirada
    </template>

    <p>
      Sua sessão expirou, realize a conexão novamente para continuar a utilizar o SaiuPraEntrega.
    </p>

    <template slot="action-btn">
      <a
        :href="uri_action"
        class="btn btn-primary text-white col-12 col-sm-4"
      >
        Ir para tela de login
      </a>
    </template>

    <div slot="dismiss-modal-text">
      Fechar
    </div>
  </SPEModal>
</template>

<script>
  import SPEModal from '../../shared/SPEModal.vue';

  export default {
    name: 'ReconnectModal',
    components: { SPEModal },
    props: {
      uri_action: {
        type: String,
        required: true,
      },

      dispatch_modal: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        has_action_button: true,
      };
    },

    watch: {
      dispatch_modal() {
        if (this.dispatch_modal) {
          return this.showModal();
        }
      },
    },

    methods: {
      showModal() {
        this.$refs.modal.showModal = true;
      },

      closeModal() {
        this.$refs.modal.showModal = false;
      },
    }
  };
</script>
