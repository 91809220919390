<template>
  <div class="container">
    <div class="card my-3">
      <div class="card-body">
        <h3 class="card-title">
          Bloquear contatos
        </h3>

        <p class="card-text">
          Para bloquear um novo contato, preencha o formulário abaixo.
        </p>

        <hr />

        <form
          :action="uri_create_opt_out"
          method="post"
          @submit="submitForm"
        >
          <input
            type="hidden"
            name="_token"
            :value="csrf_token"
          />

          <div class="row mt-2">
            <div class="col-12 col-sm-4 d-flex flex-column align-items-start">
              <label
                for="channel"
                :class="labelWithError('channel')"
              >
                Canal de notificação
              </label>

              <select
                id="channel"
                v-model="channel"
                :class="inputHasErrors('channel')"
                class="form-control mr-sm-2"
                name="channel"
                @blur="$v.channel.$touch()"
                @change="clearFeedbackErrors('channel')"
              >
                <option
                  v-for="(notification_channel,index) in channels"
                  :key="index"
                  :value="notification_channel"
                >
                  {{ renderChannelName(notification_channel) }}
                </option>
              </select>

              <div>
                <div>
                  <span
                    v-if="!$v.channel.required && $v.channel.$error"
                    class="text-danger font-weight-bold text-small"
                  >
                    O canal é obrigatório.
                  </span>
                </div>

                <div>
                  <span
                    v-if="errors.has('channel')"
                    class="text-danger font-weight-bold text-small"
                  >
                    {{ errors.get('channel') }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="phoneInputShouldRender"
              class="col-12 col-sm-4 d-flex flex-column align-items-start"
            >
              <label
                for="phone"
                :class="labelWithError('phone')"
              >
                Telefone a bloquear
              </label>

              <input
                v-model="phone"
                name="destination"
                type="hidden"
              />

              <VuePhoneNumberInput
                v-model="unformatted_phone"
                :translations="translations"
                clearable
                color="#f3b008"
                default-country-code="BR"
                error-color="#e94038"
                name="unformattedPhone"
                size="sm"
                valid-color="#f3b008"
                @update="formatPhone"
              />

              <div>
                <div v-if="!$v.phone.validPhone && $v.phone.$error">
                  <span class="text-danger font-weight-bold text-small">
                    O telefone deve ser válido no formato internacional (+55 31 99123-4567)
                  </span>
                </div>

                <div v-else-if="!$v.phone.requiredIf && $v.phone.$error">
                  <span class="text-danger font-weight-bold text-small">
                    O telefone é obrigatório
                  </span>
                </div>

                <div v-else-if="errors.has('destination')">
                  <span class="text-danger font-weight-bold text-small">
                    {{ errors.get('destination') }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-else
              class="col-12 col-sm-4 d-flex flex-column align-items-start"
            >
              <label
                for="mail"
                :class="labelWithError('mail')"
              >
                E-mail a bloquear
              </label>

              <input
                id="mail"
                v-model="mail"
                :class="inputHasErrors('mail')"
                class="form-control mr-sm-2"
                name="destination"
                placeholder="email@exemplo.com.br"
                type="text"
                @blur="$v.mail.$touch()"
                @input="clearFeedbackErrors('mail')"
              />

              <div>
                <div v-if="!$v.mail.email && $v.mail.$error">
                  <span class="text-danger font-weight-bold text-small">
                    O e-mail deve ter um formato válido
                  </span>
                </div>

                <div v-else-if="!$v.mail.requiredIf && $v.mail.$error">
                  <span class="text-danger font-weight-bold text-small">
                    O e-mail é obrigatório
                  </span>
                </div>

                <div v-else-if="!$v.mail.maxLength && $v.mail.$error">
                  <span class="text-danger font-weight-bold text-small">
                    O e-mail deve possuir no máximo {{ max_email_length }}
                  </span>
                </div>

                <div v-else-if="errors.has('destination')">
                  <span class="text-danger font-weight-bold text-small">
                    {{ errors.get('destination') }}
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-2 offset-sm-2 mt-auto">
              <button
                type="submit"
                class="btn btn-primary btn-block text-white mt-3 mt-sm-0"
              >
                Bloquear contato
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { required, email, maxLength, requiredIf } from 'vuelidate/lib/validators';
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import Errors from '../../domain/Errors';
  import channels from '../../enum/opt-out-channels';

  export default {
    name: 'CreateOptOutForm',
    components: { VuePhoneNumberInput },
    props: {
      backend_errors: {
        type: [ Array, Object ],
        required: true,
      },

      csrf_token: {
        type: String,
        required: true,
      },

      fill: {
        type: [ Array, Object ],
        required: true,
      },

      uri_create_opt_out: {
        type: String,
        required: true,
      },
    },

    validations() {
      return {
        phone: {
          required: requiredIf(function() {
            return this.channel === this.channels.phone;
          }) ,

          validNumber: function() {
            return this.valid_phone_number;
          },
        },

        mail:{
          email,
          maxLength: maxLength(this.max_email_length),
          required: requiredIf(function() {
            return this.channel === this.channels.mail;
          }),
        },

        channel: {
          required,
        },
      };
    },

    data() {
      return {
        channels,
        errors: new Errors(this.backend_errors),
        phone: '',
        mail: '',
        channel: 'mail',
        valid_phone_number: true,
        unformatted_phone: '',
        max_email_length: 255,
        translations: {
          phoneNumberLabel: '(31) 99999-9999',
          example: 'Exemplo: '
        },
      };
    },

    computed: {
      phoneInputShouldRender() {
        return this.channel === this.channels.phone;
      },
    },

    watch: {
      channel() {
        this.phone = '',
        this.mail = '';
      },
    },

    mounted() {
      if (!Array.isArray(this.fill)) {
        this.channel = this.fill.channel;
        this.phone = this.fill.phone;
        this.mail = this.fill.mail;
      }
    },

    methods: {
      renderChannelName(channel) {
        if (channel === this.channels.phone) {
          return 'Telefone';
        }

        if (channel === this.channels.mail) {
          return 'E-mail';
        }

        return '';
      },

      inputHasErrors(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'is-invalid';
        }
      },

      labelWithError(field) {
        if (this.errors.has(field) || (this.$v[field].$error)) {
          return 'text-danger';
        }
      },

      clearFeedbackErrors(field) {
        this.$v[field].$reset();
        this.errors.clear(field);
      },

      formatPhone(phone) {
        this.phone = phone.formattedNumber;
        this.valid_phone_number = phone.isValid;
      },

      submitForm(e) {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          return true;
        }

        e.preventDefault();
      },
    },
  };
</script>
