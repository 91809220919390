<template>
  <div class="dashboard-container">
    <SPEDropdownMenu @changed-period="fetchNewData" />
    <SPEPlanCapacityGaugeContainer @show-modal="dispatchModal" />
    <SPEPackagesStatusContainer
      ref="packagesStatus"
      @show-modal="dispatchModal"
    />

    <SPEPackagesPerCarrierCartContainer
      ref="packagesPerCarrierChart"
      @show-modal="dispatchModal"
    />

    <SPEPackagesPerMonthChartContainer
      ref="packagesPerMonthChart"
      @show-modal="dispatchModal"
    />

    <SPESMSNotificationsSentAndDeliveredChartContainer
      ref="smsNotificationsChart"
      @show-modal="dispatchModal"
    />

    <SPEMailNotificationsSentAndDeliveredChartContainer
      ref="emailNotificationsChart"
      @show-modal="dispatchModal"
    />

    <SPEOptOutChartContainer
      ref="optoutChart"
      @show-modal="dispatchModal"
    />

    <SPEAverageTimeContainer
      ref="averageTime"
      @show-modal="dispatchModal"
    />

    <SPEReconnectModal
      :uri_action="uri_login"
      :dispatch_modal="dispatch_modal"
    />
  </div>
</template>

<script>
  import SPEDropdownMenu from './partials/SPEDropdownMenu.vue';
  import SPEAverageTimeContainer from './SPEAverageTimeContainer.vue';
  import SPEMailNotificationsSentAndDeliveredChartContainer from './SPEMailNotificationsSentAndDeliveredChartContainer.vue';
  import SPEPackagesPerCarrierCartContainer from './SPEPackagesPerCarrierCartContainer.vue';
  import SPEPackagesPerMonthChartContainer from './SPEPackagesPerMonthChartContainer.vue';
  import SPEPlanCapacityGaugeContainer from './SPEPlanCapacityGaugeContainer.vue';
  import SPEReconnectModal from './partials/SPEReconnectModal.vue';
  import SPEOptOutChartContainer from './SPEOptOutChartContainer.vue';
  import SPEPackagesStatusContainer from './SPEPackagesStatusContainer.vue';
  import SPESMSNotificationsSentAndDeliveredChartContainer from './SPESMSNotificationsSentAndDeliveredChartContainer.vue';

  export default {
    name: 'DashoboardContainer',
    components: {
      SPEAverageTimeContainer,
      SPEDropdownMenu,
      SPEMailNotificationsSentAndDeliveredChartContainer,
      SPEOptOutChartContainer,
      SPEPackagesPerCarrierCartContainer,
      SPEPackagesPerMonthChartContainer,
      SPEPackagesStatusContainer,
      SPEPlanCapacityGaugeContainer,
      SPEReconnectModal,
      SPESMSNotificationsSentAndDeliveredChartContainer,
    },

    props: {
      uri_login: {
        required: true,
        type: String,
      },
    },

    data() {
      return {
        dispatch_modal: false,
      };
    },

    methods: {
      dispatchModal() {
        this.dispatch_modal = true;
      },

      fetchNewData(period) {
        this.$refs.averageTime.fetchChartData(period);
        this.$refs.emailNotificationsChart.fetchChartData(period);
        this.$refs.optoutChart.fetchChartData(period);
        this.$refs.packagesPerCarrierChart.fetchChartData(period);
        this.$refs.packagesPerMonthChart.fetchChartData(period);
        this.$refs.packagesStatus.fetchChartData(period);
        this.$refs.smsNotificationsChart.fetchChartData(period);
      },
    },
  };
</script>

<style scoped>
  .dashboard-container {
    display: grid;
    grid-template-rows: 25px 1fr 25px 1fr 40px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    min-height: calc(100vh - 66px);
    width: 100%;
    padding-bottom: 6px;
  }

  @media (max-width: 980px) {
    .dashboard-container {
      display: flex;
      flex-direction: column;
    }
  }
</style>
