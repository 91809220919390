<template>
  <div class="d-flex flex-column justify-content-center align-items-center chart-limited-height">
    <v-gauge
      :max-value="max_capacity"
      :min-value="min_capacity"
      :options="options"
      :top="true"
      :value="used_capacity"
      height="130px"
      unit="pacotes"
      width="210px"
    />

    <strong class="info-content">
      Máximo de pacotes cadastrados: {{ max_capacity }}
    </strong>
  </div>
</template>

<script>
  import VGauge from 'vgauge';

  export default {
    name: 'GaugeChart',
    components: { VGauge },
    props: {
      used_capacity: {
        type: Number,
        default: 0,
      },

      max_capacity: {
        type: Number,
        default: 0,
      },

      min_capacity: {
        type: Number,
        default: 0,
      }
    },

    data() {
      return {
        options: {
          angle: 0,
          lineWidth: 0.5,
          limitMax: true,
          generateGradient: true,
          fontSize: 36,
          pointer: {
            length: 0.6,
            strokeWidth: 0.035,
            color: '#000000',
          },
          staticZones: [
            { strokeStyle: '#3fd3a9', min: this.min_capacity, max: this.max_capacity / 3 },
            { strokeStyle: '#f3b008', min: this.max_capacity / 3, max: this.max_capacity / 1.5 },
            { strokeStyle: '#e94038', min: this.max_capacity / 1.5, max: this.max_capacity },
          ],
        }
      };
    },

    computed: {
      renderScale() {
        return this.max_capacity / 5;
      },
    },
  };
</script>

<style scoped>
  .chart-limited-height { height: 130px; }
  .info-content { margin-top: -20px;}
</style>
