<template>
  <div>
    <div class="row my-3">
      <div class="col-12 col-sm-4 d-flex flex-column align-items-start mb-2 mb-sm-0">
        <label for="tracking_code">
          {{ $t('fields.packages.tracking_code') }}
        </label>

        <input
          id="tracking_code"
          v-model="tracking_code"
          type="text"
          class="form-control mr-sm-2"
          name="tracking_code"
          placeholder="BR1223321312123X"
        />
      </div>

      <div class="col-12 col-sm-4 d-flex flex-column align-items-start">
        <label for="description">
          {{ $t('fields.packages.description') }}
        </label>

        <input
          id="description"
          v-model="description"
          type="text"
          class="form-control mr-sm-2"
          name="description"
          placeholder="Controle de TV"
        />
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 col-sm-4 d-flex flex-column align-items-start mb-2 mb-sm-0">
        <label for="customer_name">
          {{ $t('fields.packages.customer_name') }}
        </label>

        <input
          id="customer_name"
          v-model="customer_name"
          type="text"
          class="form-control mr-sm-2"
          name="customer_name"
          placeholder="João Pedro"
        />
      </div>

      <div class="col-12 col-sm-4 d-flex flex-column align-items-start">
        <label for="latest_event">
          {{ $t('fields.packages.latest_event') }}
        </label>

        <select
          id="latest_event"
          v-model="latest_event"
          class="form-control mr-sm-2"
          name="latest_event"
        >
          <option value="">
            {{ $t('packages.select_status') }}
          </option>

          <option
            v-for="(event, index) in available_events"
            :key="index"
            :value="event"
          >
            {{ event }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-sm-4 d-flex flex-column align-items-start">
        <label for="shipping_company">
          {{ $t('fields.packages.shipping_company') }}
        </label>

        <select
          id="shipping_company"
          v-model="shipping_company"
          class="form-control mr-sm-2"
          name="shipping_company"
        >
          <option value="">
            {{ $t('packages.select_shipping_company') }}
          </option>

          <option
            v-for="(company, index) in shipping_companies"
            :key="index"
            :value="company"
          >
            {{ capitalizeFirstLetter(company) }}
          </option>
        </select>
      </div>

      <div class="col-12 col-sm-2 offset-sm-6 mt-auto">
        <button
          type="button"
          class="btn btn-primary btn-block text-white mt-3 mt-sm-0"
          @click="fetchPackagesFiltered"
        >
          {{ $t('actions.common.fetch') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import capitalizeFirstLetter from '../../../helpers/capitalize-first-letter';

  export default {
    name: 'PackagesSearch',
    props: {
      available_events: {
        type: Array,
        required: true,
      },

      shipping_companies: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        tracking_code: '',
        description: '',
        customer_name: '',
        latest_event: '',
        shipping_company: '',
      };
    },

    methods: {
      capitalizeFirstLetter,
      fetchPackagesFiltered() {
        this.$emit('fetch-filtered-packages', {
          customer_name: this.customer_name,
          description: this.description,
          latest_event: this.latest_event,
          shipping_company: this.shipping_company,
          tracking_code: this.tracking_code,
        });
      }
    },
  };
</script>
