<template>
  <section class="row bg-primary">
    <div class="col-12">
      <div class="card-row d-flex flex-column justify-content-center align-items-center tracking-table-container">
        <div class="card col-12 col-sm-6 p-0 mt-3">
          <div class="card-body">
            <h3 class="card-title">
              Não receber mais notificações
            </h3>

            <p>
              Você solicitou parar de receber notificações sobre os pacotes. É necessário que confirme esta ação.
            </p>

            <div class="row">
              <div class="col-12 col-md-4 offset-md-8">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-block"
                  @click="dispatchModal"
                >
                  Não receber mais notificações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SPEOptOutConfirmModal
      ref="optOutConfirmModal"
      :csrf_token="csrf_token"
      :hash="hash"
      :uri_action="uri_confirm_opt_out"
    />
  </section>
</template>

<script>
  import SPEOptOutConfirmModal from './SPEOptOutConfirmModal.vue';

  export default {
    name: 'OptOutConfirmation',
    components: { SPEOptOutConfirmModal },
    props: {
      uri_confirm_opt_out: {
        type: String,
        required: true,
      },

      hash: {
        type: String,
        required: true,
      },

      csrf_token: {
        type: String,
        required: true,
      },
    },

    methods: {
      dispatchModal() {
        this.$refs.optOutConfirmModal.showModal();
      },
    },
  };
</script>
