export default {
  packages: ':packages_number pacotes por mês',
  email: 'Sem limite de emails',
  tracking: 'Rastreamento de todas as transportadoras',
  integrations: 'Integração com todas as plataformas de e-commerce',
  webhooks: 'API/Webhooks',
  advanced_reports: 'Relatórios avançados',
  sms: 'Envio de :sms_number SMS',
  actual_plan: 'Plano atual',
  subscribe_now: 'Assinar agora',
};
