<template>
  <input
    id="customer_phone"
    v-model="customer_phone"
    v-mask="['(##) #####-####', '(##) ####-####']"
    :class="invalid_class"
    placeholder="(31) 99999-9999"
    type="text"
    class="form-control align-self-end mr-sm-2"
    name="customer_phone"
  />
</template>

<script>
  export default {
    name: 'ShowPackageInputPhone',
    props: {
      phone: {
        type: String,
        default: '',
      },

      invalid_class: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        customer_phone: '',
      };
    },

    mounted() {
      this.customer_phone = this.phone;
    },
  };
</script>
